import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  DatePicker,
  Button,
  Radio,
} from "antd";
import { MakeApiCall } from "../../../../apis";
import dayjs from "dayjs";
import moment from "moment";

const { Option } = Select;

const AddCouponCodeManager = ({ visible, onCancel, onSubmit, selectedRow }) => {
  console.log(selectedRow, "selectedRow");

  const [form] = Form.useForm();
  const [expiryDate, setExpiryDate] = useState(null);

  const [partnerList, setPartnerList] = useState([]);
  const [partnerLoading, setPartnerLoading] = useState(true);

  const getPartners = async () => {
    setPartnerLoading(true);
    const response = await MakeApiCall(`partner/list`, "get", null, true);

    if (response?.status) {
      setPartnerLoading(false);
      setPartnerList(
        Object.entries(response?.data)?.map(([key, value]) => ({
          label: value,
          value: key,
        }))
      );
    } else {
      setPartnerList([]);
      setPartnerLoading(false);
    }
  };

  useEffect(() => {
    getPartners();
    return () => {};
  }, []);

  const handleDateChange = (date) => {
    setExpiryDate(date);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = (values) => {
    onSubmit({
      ...values,
      expiry_date: values?.expiry_date
        ? dayjs(values?.expiry_date).format("YYYY-MM-DD")
        : null,
    });
  };

  return (
    <Modal
      visible={visible}
      title={`${Object.keys(selectedRow).length === 0 ? "Add" : "Edit"}`}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={form.submit}>
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          ...selectedRow,
          partner_id: selectedRow?.partner_id?.toString() || null,
          expiry_date: selectedRow?.expiry_date
            ? dayjs(selectedRow?.expiry_date, "YYYY-MM-DD")
            : null,
        }}
        layout="vertical"
      >
        <Form.Item
          name="partner_id"
          label="Partner name"
          rules={[{ required: true, message: "Please select a partner" }]}
        >
          <Select loading={partnerLoading} placeholder="Select a partner">
            {partnerList?.map((d, i) => (
              <Option value={d?.value} key={i}>
                {d?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="coupon_amount"
          label="Amount (%)"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <Input type="number" placeholder="Enter the amount" />
        </Form.Item>

        <Form.Item name="applies_to" label="Applies to">
          <Radio.Group>
            <Radio value="first_month">First month</Radio>
            <Radio value="life">Life</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="coupon_code" label="Coupon code">
          <Input placeholder="Enter coupon code" />
        </Form.Item>

        <Form.Item name="expiry_date" label="Set expiry">
          <DatePicker
            disabledDate={(current) => {
              // Can not select days before today
              return current && current < moment().startOf("day");
            }}
            format={"YYYY-MMM-DD"}
            onChange={handleDateChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCouponCodeManager;
