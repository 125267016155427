import { Popconfirm, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import AddData from "./lib";
import { Wrapper } from "./style";

const MetricGroup = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  const deleteAction = async (id) => {
    const response = await MakeApiCall(
      `metric-group/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setLoading(true);
      setPage(1);
      getList({ page: 1 });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const getList = async (data) => {
    const response = await MakeApiCall(
      `metric-group?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      setList([]);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };

  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };

  useEffect(() => {
    getList({
      ...sortFilters,
    });
    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
      filterIndex: "group_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      filterIndex: "status",
      key: "status",
      render: (e) => {
        return (
          <Tag color={e === 1 ? "green" : "blue"}>
            {e === 1 ? "Active" : "Pause"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              setAddModal(true);
              setModalType("Update");
              setSelectedRow(text);
            }}
            className="me-2"
          >
            {EditIcon}
          </a>
          {/* <Popconfirm
            title="Delete this Metric-groups"
            description={`Are you sure to delete this ${text?.group_name}?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              deleteAction(text?.id);
            }}
            placement="left"
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="me-2">
              {DeleteIcon}
            </a>
          </Popconfirm> */}
          {/* <a
            href="#"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
          >
            <i className="ki-outline ki-row-vertical fs-3 text-dark" />
          </a> */}
        </span>
      ),
    },
  ];

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title"></div>
          <div className="card-toolbar">
            <a
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
              className="add-btn "
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Metric Groups
            </a>
          </div>
        </div>
        <div className="card-body pt-0 pb-0">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className="">
              <Table
                onChange={handleChange}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                columns={columns?.map((d) => ({
                  ...d,
                  className: "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                dataSource={list}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
              />
            </div>
          )}
        </div>
      </div>
      {addModal && (
        <AddData
          visible={addModal}
          selectedRow={selectedRow}
          modalType={modalType}
          onClose={() => {
            setSelectedRow({});
            setAddModal(false);
          }}
          callAPI={() => {
            setPage(1);
            getList({ page: 1 });
          }}
        />
      )}
    </Wrapper>
  );
};

export default MetricGroup;
