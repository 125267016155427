import { filter } from "@amcharts/amcharts4/.internal/core/utils/Iterator";
import { DatePicker, Tag, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { TableLoading } from "../../../../components/table-animation";

const TrafficTables = ({
  getValueShow,
  icons,
  setSelectedRow,
  setSummaryDates,
  summaryDates,
  convertData,
  selectedTabFilter,
  GetLineItems,
}) => {
  const [tableLoading, setTableLoading] = useState(true);

  const [tableData, setTableData] = useState({
    search_query: [],
    search_catalog: [],
    top_search_terms: [],
    item_performance: [],
  });

  const columnsFilterTop = {
    [`Current Period`]: "current_period",
    [`Last Period`]: "last_period",
    [`Variance`]: "variance",
    [`%`]: "variance_pect",
  };

  const getPremium = async (data) => {
    setTableLoading(true);

    setTableData({
      search_query: [],
      search_catalog: [],
      top_search_terms: [],
      item_performance: [],
    });

    try {
      const response = await MakeApiCall(
        `funnel/get-premium-content?start_date=${
          data?.start_date || ""
        }&end_date=${data?.end_date || ""}&selection_type=${
          data?.selection_type === "1" ? "month" : "week"
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setTableData({
          search_query: convertData(response?.data?.search_query),
          search_catalog: convertData(response?.data?.search_catalog),
          top_search_terms: convertData(response?.data?.top_search_terms),
          item_performance: convertData(response?.data?.item_performance),
        });

        setTableLoading(false);
      } else {
        setTableLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setTableLoading(false);

      setTableData({
        search_query: [],
        search_catalog: {},
        top_search_terms: [],
        item_performance: [],
      });

      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    const { start_date, end_date } = summaryDates;
    setTableLoading(true);
    getPremium({
      selection_type: selectedTabFilter,
      start_date: start_date,
      end_date: end_date,
    });
    return () => {};
  }, [selectedTabFilter, summaryDates]);

  function formatNumberWithCommas(number) {
    return number.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="card-title"></div>
          <div className="card-toolbar">
            {summaryDates?.start_date && summaryDates?.end_date && (
              <div className="d-flex align-items-center">
                <Tag
                  style={{
                    fontSize: "14px",
                    padding: "4px",
                  }}
                  color="blue"
                  bordered={false}
                >
                  {moment(summaryDates?.start_date)?.format("YYYY-MMM-DD")}
                  &nbsp;&nbsp;&nbsp;{`->`}&nbsp;&nbsp;&nbsp;
                  {moment(summaryDates?.end_date)?.format("YYYY-MMM-DD")}
                </Tag>
              </div>
            )}
            {selectedTabFilter !== "1" ? (
              <DatePicker
                onChange={(e, _, Y) => {
                  const year = parseInt(_?.split("-")?.[0]);
                  const weekNumber = parseInt(
                    _?.split("-")?.[1]?.replace("th", "")
                  );

                  // Get the date of the first day of the year
                  const firstDayOfYear = new Date(year, 0, 1);

                  // Calculate the first day of the week
                  const firstDayOfTargetWeek = new Date(
                    firstDayOfYear.getTime() +
                      (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
                  );

                  // Calculate the last day of the week
                  const lastDayOfTargetWeek = new Date(
                    firstDayOfTargetWeek.getTime() + 6 * 24 * 60 * 60 * 1000
                  );

                  // Subtract one day from both start and end dates
                  const startDateOneDayBack = new Date(
                    firstDayOfTargetWeek.getTime() - 1 * 24 * 60 * 60 * 1000
                  );
                  const endDateOneDayBack = new Date(
                    lastDayOfTargetWeek.getTime() - 1 * 24 * 60 * 60 * 1000
                  );

                  // Output the results
                  const obj = {
                    start_date: dayjs(startDateOneDayBack).format("YYYY-MM-DD"),
                    end_date: dayjs(endDateOneDayBack).format("YYYY-MM-DD"),
                  };
                  setSummaryDates(obj);
                  const { start_date, end_date } = obj;
                  // setTableLoading(true);
                  // getPremium({
                  //   selection_type: selectedTabFilter,
                  //   start_date: start_date,
                  //   end_date: end_date,
                  // });
                }}
                value={dayjs(summaryDates?.start_date, `YYYY-MM-DD`)}
                picker="week"
                needConfirm
                className="me-3"
              />
            ) : (
              <DatePicker
                value={dayjs(summaryDates?.start_date, `YYYY-MM-DD`)}
                needConfirm
                onChange={(e, _) => {
                  const year = parseInt(_?.split("-")?.[0]);
                  const month = parseInt(_?.split("-")?.[1]); // Note: JavaScript months are zero-based, so June is represented by 5
                  const firstDayOfMonth = new Date(year, month - 1, 1);
                  const lastDayOfMonth = new Date(year, month, 0);
                  const obj = {
                    start_date: dayjs(firstDayOfMonth).format("YYYY-MM-DD"),
                    end_date: dayjs(lastDayOfMonth).format("YYYY-MM-DD"),
                  };

                  setSummaryDates(obj);
                  // const { start_date, end_date } = obj;
                  // setTableLoading(true);
                  // getPremium({
                  //   selection_type: selectedTabFilter,
                  //   start_date: start_date,
                  //   end_date: end_date,
                  // });
                }}
                picker="month"
              />
            )}
          </div>
        </div>
        <div className="card-body pt-0">
          {tableLoading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className="table-responsive">
              <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
                <thead>
                  <tr>
                    <th style={{ maxWidth: "10px", minWidth: "10px" }} />
                    {Object?.entries(columnsFilterTop)?.map(
                      ([key, value], i) => {
                        return (
                          <th
                            key={i}
                            style={{
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                            className={`${key} text-end  pe-3 py-3 min-w-200px`}
                          >
                            {key}
                          </th>
                        );
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      style={{ background: "transparent" }}
                      className="d-flex align-items-center fixed-column justify-content-start"
                    >
                      <h4
                        style={{
                          marginBottom: "0px",
                          color: "#515151",
                          fontWeight: 500,
                        }}
                      >
                        Top 1000 Search Terms Summary
                      </h4>
                    </td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={5} />
                  </tr>
                  {tableData?.search_query?.map((d, i) => (
                    <tr key={i}>
                      <td
                        className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                        style={{ borderBottom: "1px solid #E5E5E5" }}
                      >
                        <div
                          onClick={() => {
                            GetLineItems({
                              metric_name: tableData?.search_query?.map(
                                (d) => d?.type
                              ),
                              type: d?.type,
                              columns: "Top1000SearchTermsSummary",
                              summary_type: "search_query",
                              filterType: "select_year",
                              start_date: summaryDates?.start_date,
                              end_date: summaryDates?.end_date,
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {d?.type
                              ?.split("_")
                              ?.join(" ")
                              ?.replace("sip", "SIP")}
                          </span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(columnsFilterTop)?.map(
                        ([key, value], i) => {
                          return (
                            <td
                              key={i}
                              className="text-end pe-3"
                              style={{
                                borderBottom: `1px solid #E5E5E5`,
                              }}
                            >
                              {value === "expected_variance_pr" ||
                              value === "variance_pect"
                                ? getValueShow(d?.[value])
                                : d?.[value]
                                ? formatNumberWithCommas(d?.[value])
                                : ""}
                              {d?.type === "brand_impression_share" ||
                              d?.type === "sip_click_score" ||
                              d?.type === "avg_cart_add_rate" ||
                              d?.type === "avg_purchase_rate" ||
                              d?.type === "brand_purchase_rate" ||
                              d?.type === "top_keyword_traffic_ratio" ||
                              d?.type === "alternate_traffic_ratio" ||
                              d?.type === "brand_cart_add_rate" ||
                              d?.type === "sip_cart_score" ||
                              d?.type === "sip_purchase_score" ||
                              // d?.type === "avg_search_query_score" ||
                              "brand_click_share" === d?.type
                                ? value === "variance_pect"
                                  ? ""
                                  : "%"
                                : d?.type === "avg_click_rate" &&
                                  value !== "variance_pect"
                                ? "%"
                                : ""}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  ))}
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      style={{ background: "transparent" }}
                      className="d-flex align-items-center fixed-column justify-content-start"
                    >
                      <h4
                        style={{
                          marginBottom: "0px",
                          color: "#515151",
                          fontWeight: 500,
                        }}
                      >
                        Search Catalog Performance Summary
                      </h4>
                    </td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={5} />
                  </tr>
                  {tableData?.search_catalog?.map((d, i) => (
                    <tr key={i}>
                      <td
                        className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                        style={{ borderBottom: "1px solid #E5E5E5" }}
                      >
                        <div
                          onClick={() => {
                            GetLineItems({
                              metric_name: tableData?.search_catalog?.map(
                                (d) => d?.type
                              ),
                              type: d?.type,
                              columns: "SearchCatalogPerformanceSummary",
                              summary_type: "search_catalog",
                              filterType: "week_number",
                              start_date: summaryDates?.start_date,
                              end_date: summaryDates?.end_date,
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {d?.type
                              ?.split("_")
                              ?.join(" ")
                              ?.replace("ctr", "CTR")}
                          </span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>

                      {Object?.entries(columnsFilterTop)?.map(
                        ([key, value], i) => {
                          return (
                            <td
                              key={i}
                              className="text-end pe-3"
                              style={{
                                borderBottom: `1px solid #E5E5E5`,
                              }}
                            >
                              {value === "expected_variance_pr" ||
                              value === "variance_pect"
                                ? getValueShow(d?.[value])
                                : d?.[value]
                                ? formatNumberWithCommas(d?.[value])
                                : ""}
                              {d?.type === "avg_ctr" &&
                              value !== "variance_pect"
                                ? "%"
                                : ""}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  ))}
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  {/* <tr>
                    <td
                      style={{ background: "transparent" }}
                      className="d-flex align-items-center fixed-column justify-content-start"
                    >
                      <h4
                        style={{
                          marginBottom: "0px",
                          color: "#515151",
                          fontWeight: 500,
                        }}
                      >
                        Top Search Terms Summary
                      </h4>
                    </td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={5} />
                  </tr>
                  {tableData?.top_search_terms?.map((d, i) => (
                    <tr key={i}>
                      <td
                        className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                        style={{ borderBottom: "1px solid #E5E5E5" }}
                      >
                        <div
                          onClick={() => {
                            GetLineItems({
                              metric_name: tableData?.top_search_terms?.map(
                                (d) => d?.type
                              ),
                              type: d?.type,
                              columns: "TopSearchTermsSummary",
                              summary_type: "top_search_terms",
                              filterType: "week_number",
                              start_date: summaryDates?.start_date,
                              end_date: summaryDates?.end_date,
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {d?.type?.split("_")?.join(" ")}
                          </span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>

                      {Object?.entries(columnsFilterTop)?.map(
                        ([key, value], i) => {
                          return (
                            <td
                              key={i}
                              className="text-end pe-3"
                              style={{
                                borderBottom: `1px solid #E5E5E5`,
                              }}
                            >
                              {value === "expected_variance_pr" ||
                              value === "variance_pect"
                                ? getValueShow(d?.[value])
                                : d?.[value]
                                ? formatNumberWithCommas(d?.[value])
                                : ""}
                              {(d?.type === "avg_ctr1" ||
                                d?.type === "avg_ctr2" ||
                                d?.type === "avg_ctr3") &&
                              value !== "variance_pect"
                                ? "%"
                                : ""}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  ))} */}
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      style={{ background: "transparent" }}
                      className="d-flex align-items-center fixed-column justify-content-start"
                    >
                      <h4
                        style={{
                          marginBottom: "0px",
                          color: "#515151",
                          fontWeight: 500,
                        }}
                      >
                        Item Performance
                      </h4>
                    </td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={5} />
                  </tr>
                  {tableData?.item_performance?.map((d, i) => (
                    <tr key={i}>
                      <td
                        className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                        style={{ borderBottom: "1px solid #E5E5E5" }}
                      >
                        <div
                          onClick={() => {
                            GetLineItems({
                              metric_name: tableData?.item_performance?.map(
                                (d) => d?.type
                              ),
                              type: d?.type,
                              columns: "ItemPerformance",
                              summary_type: "item_performance",
                              filterType: "week_number",
                              start_date: summaryDates?.start_date,
                              end_date: summaryDates?.end_date,
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {d?.type
                              ?.split("_")
                              ?.join(" ")
                              ?.replace("b2b", "B2B")
                              ?.replace("usp", "USP")}
                          </span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>

                      {Object?.entries(columnsFilterTop)?.map(
                        ([key, value], i) => {
                          return (
                            <td
                              key={i}
                              className="text-end pe-3"
                              style={{
                                borderBottom: `1px solid #E5E5E5`,
                              }}
                            >
                              {console.log(d?.type, "d?.type")}
                              {value === "expected_variance_pr" ||
                              value === "variance_pect"
                                ? getValueShow(d?.[value])
                                : d?.[value]
                                ? formatNumberWithCommas(d?.[value])
                                : ""}
                              {(d?.type === "buy_box_pect" ||
                                d?.type === "usp_pect" ||
                                d?.type === "browser_page_view_rate" ||
                                d?.type === "browser_session_rate" ||
                                d?.type === "click_to_sales_pect") &&
                              value !== "variance_pect"
                                ? "%"
                                : ""}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrafficTables;
