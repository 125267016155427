import { Button, Result } from "antd";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import RootLoading from "../components/loading-root";
import ApplicationLog from "../modules/admin/application-logs";
import CouponCodeManager from "../modules/admin/coupon-code-manager";
import AdminDashboard from "../modules/admin/dashboard";
import EmailDefinitions from "../modules/admin/email-definitions";
import ManageBilling from "../modules/admin/manage-billing";
import ManageUser from "../modules/admin/manage-users";
import MappingFinanceEvents from "../modules/admin/mapping-finance-events";
import MetricGroup from "../modules/admin/metric-management/metric-groups";
import Metric from "../modules/admin/metric-management/metrics";
import Pages from "../modules/admin/metric-management/pages";
import SellerResources from "../modules/admin/seller-resources";
import SellerTracking from "../modules/admin/seller-tracking";
import Sellers from "../modules/admin/sellers";
import AdDefinitions from "../modules/admin/setting/ad-definitions";
import AdminScheduler from "../modules/admin/setting/admin-scheduler";
import AppConfigurations from "../modules/admin/setting/app-configurations";
import VersionTracking from "../modules/admin/setting/version-tracking";
import Subscription from "../modules/admin/subscription";
import SystemParameters from "../modules/admin/settings";
import CredentialsError from "../modules/credential-page";
import JTBDUser from "../modules/user/JTBD";
import AdvertisingDashboard from "../modules/user/advertising-dashboard";
import Ads360 from "../modules/user/advertising/lib/ads-360";
import Keywords360 from "../modules/user/advertising/lib/keywords-360";
import PerformanceRating from "../modules/user/advertising/lib/performance-rating";
import CentralLog from "../modules/user/application-log/central-log";
import DataScheduler from "../modules/user/application-log/data-scheduler";
import SystemLog from "../modules/user/application-log/system-log";
import Financial from "../modules/user/business/lib/financial";
import Sales360 from "../modules/user/business/lib/sales-360";
import Traffic360 from "../modules/user/business/lib/traffic-360";
import Dashboard from "../modules/user/dashboard";
import DataSources from "../modules/user/data-sources";
import PortfolioPerformance from "../modules/user/funnel/lib/portfolio-performance";
import PrioritizedSearchTerms from "../modules/user/funnel/lib/prioritized-search-terms";
import SearchRankTracking from "../modules/user/funnel/lib/search-rank-tracking";
import SearchScoreTracking from "../modules/user/funnel/lib/search-score-tracking";
import Setting from "../modules/user/marketplace";
import Performance360 from "../modules/user/performance-360";
import RequestSupport from "../modules/user/request-support";
import Resources from "../modules/user/resources";
import SettingProfile from "../modules/user/setting";
import SellerProfile from "../modules/user/setting/lib/seller profile";
import Alerts from "../modules/user/utilities/lib/alerts";
import DataIntegrity from "../modules/user/utilities/lib/data-integrity";
import ManageEvents from "../modules/user/utilities/lib/manage-events";
import ManageSkuEvents from "../modules/user/utilities/lib/manage-sku-events";
import SystemParametersUser from "../modules/user/utilities/lib/system-parameters";
import AdminAlert from "../modules/admin/admin-alerts";
import MetricsMenuItemMapping from "../modules/admin/metric-management/metrics-menu-item-mapping";
import ParameterGroups from "../modules/admin/settings/parameter-groups";
import FtpCredentials from "../modules/user/setting/ftp-credentials";

const PageRouter = ({ type, pageTitle, menusList }) => {
  return (
    <Suspense fallback={<RootLoading />}>
      <>
        {type === "admin" && (
          <Switch>
            {[
              { path: "/", component: AdminDashboard },
              { path: "/dashboard", component: AdminDashboard },
              { path: "/logs/admin-alerts", component: AdminAlert },

              { path: "/manage-seller", component: ManageUser },
              { path: "/manage-billing", component: ManageBilling },
              { path: "/subscription", component: Subscription },
              {
                path: "/settings/coupon-codes",
                component: CouponCodeManager,
              },
              {
                path: "/settings/app-configurations",
                component: AppConfigurations,
              },
              { path: "/settings/admin-scheduler", component: AdminScheduler },
              { path: "/settings/ad-definitions", component: AdDefinitions },
              {
                path: "/settings/version-tracking",
                component: VersionTracking,
              },
              {
                path: "/metric-management/metric-groups",
                component: MetricGroup,
              },
              {
                path: "/metric-management/metric-mapping",
                component: MetricsMenuItemMapping,
              },
              { path: "/metric-management/metrics", component: Metric },
              // { path: "/metric-management/pages", component: Pages },
              { path: "/seller-resources", component: SellerResources },
              { path: "/application-log", component: ApplicationLog },
              {
                path: "/settings/finance-mapping",
                component: MappingFinanceEvents,
              },
              { path: "/email-definitions", component: EmailDefinitions },

              { path: "/settings/parameters", component: SystemParameters },
              {
                path: "/settings/parameter-groups",
                component: ParameterGroups,
              },
              { path: "/sellers", component: Sellers },
              { path: "/logs/seller-activity", component: SellerTracking },
              // { path: "/settings/premium-content", component: PremiumContent },
              { path: "/version-tracking", component: VersionTracking },
              // Additional routes can be added here as needed
            ].map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.component}
                exact={true}
              />
            ))}
            <Route
              path="*"
              render={() => (
                <div className="d-flex align-items-center justify-content-center w-100">
                  <Result
                    status={"404"}
                    subTitle="Sorry, the page you visited does not exist."
                    title="404"
                    extra={
                      <Button href="/" type="primary">
                        Back to Home
                      </Button>
                    }
                  />
                </div>
              )}
            />
          </Switch>
        )}
        {type === "users" && (
          <Switch>
            {[
              { path: "/", component: Dashboard },
              { path: "/dashboard", component: Dashboard },
              { path: "/ftp-credentials", component: FtpCredentials },
              { path: "/advertising/ads-360", component: Ads360 },
              { path: "/callbackads", component: Setting },
              { path: "/spapi-callback", component: Setting },
              { path: "/spapi-callback", component: Setting },
              { path: "/profile", component: SettingProfile },
              { path: "/reset-password", component: SettingProfile },
              { path: "/seller-profile", component: SellerProfile },
              { path: "/advertising/keywords-360", component: Keywords360 },
              { path: "/ads-360", component: AdvertisingDashboard },
              {
                path: "/advertising/performance-rating",
                component: PerformanceRating,
              },
              { path: "/business-360", component: Financial },
              { path: "/sales-360", component: Sales360 },
              { path: "/funnel-360", component: Traffic360 },
              { path: "/data-sources", component: DataSources },
              {
                path: "/funnel/search-score-tracking",
                component: SearchScoreTracking,
              },
              { path: "/callbackads", component: Setting, exact: true },
              { path: "/spapi-callback", component: Setting, exact: true },
              {
                path: "/funnel/search-rank-tracking",
                component: SearchRankTracking,
              },
              {
                path: "/funnel/prioritized-search-terms",
                component: PrioritizedSearchTerms,
              },
              {
                path: "/funnel/portfolio-performance",
                component: PortfolioPerformance,
              },
              { path: "/JTBD", component: JTBDUser },
              { path: "/request-support", component: RequestSupport },
              { path: "/resources", component: Resources },
              { path: "/utilities/data-integrity", component: DataIntegrity },
              { path: "/utilities/alerts", component: Alerts },
              { path: "/utilities/manage-events", component: ManageEvents },
              {
                path: "/utilities/manage-sku-events",
                component: ManageSkuEvents,
              },
              {
                path: "/system-parameters",
                component: SystemParametersUser,
              },
              { path: "/central-log", component: CentralLog },
              { path: "/system-log", component: SystemLog },
              {
                path: "/data-scheduler",
                component: DataScheduler,
              },
              { path: "/performance-360", component: Performance360 },
            ].map((route, index) => (
              <Route
                key={index}
                path={route.path}
                render={(props) => (
                  <route.component
                    pageTitle={pageTitle}
                    menusList={menusList}
                  />
                )}
                // component={route.component}
                exact={true}
              />
            ))}
            <Route
              path="*"
              render={() => (
                <div className="d-flex align-items-center justify-content-center w-100">
                  <Result
                    status={"404"}
                    subTitle="Sorry, the page you visited does not exist."
                    title="404"
                    extra={
                      <Button href="/" type="primary">
                        Back to Home
                      </Button>
                    }
                  />
                </div>
              )}
            />
          </Switch>
        )}
        {type === 0 && (
          <Switch>
            {[
              { path: "/callbackads", component: Setting, exact: true },
              { path: "/spapi-callback", component: Setting, exact: true },
              {
                path: "/spapi-callback",
                component: Setting,
                exact: true,
              },
              {
                path: "/profile",
                component: SettingProfile,
                exact: true,
              },
              {
                path: "/setting/reset-password",
                component: SettingProfile,
                exact: true,
              },
              {
                path: "/seller-profile",
                component: SellerProfile,
                exact: true,
              },
              { path: "/", component: CredentialsError, exact: true },
            ].map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
            <Route
              path="*"
              render={() => (
                <div className="d-flex align-items-center justify-content-center w-100">
                  <Result
                    status={"404"}
                    subTitle="Sorry, the page you visited does not exist."
                    title="404"
                    extra={
                      <Button href="/" type="primary">
                        Back to Home
                      </Button>
                    }
                  />
                </div>
              )}
            />
          </Switch>
        )}
      </>
    </Suspense>
  );
};

export default PageRouter;
