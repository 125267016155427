import { DatePicker, Empty, Input, Select, Table, message } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ErrorModal } from "../../../../components/upload-modals";
import { RenderTable } from "../../../../config";
dayjs.extend(quarterOfYear);
const SbPurchases = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [filters, setFilters] = useState({
    campaign_name: null,
    campaign_status: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const columns = [
    {
      title: "Report Date",
      dataIndex: "reportDate",
      key: "reportDate",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      width: 200,
    },
    {
      title: "Budget Currency",
      dataIndex: "budgetCurrency",
      key: "budgetCurrency",
    },
    {
      title: "Attribution Type",
      dataIndex: "attributionType",
      key: "attributionType",
    },
    {
      title: "Purchased ASIN",
      dataIndex: "purchasedASIN",
      key: "purchasedASIN",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Product Category",
      dataIndex: "productCategory",
      key: "productCategory",
    },
    {
      title: "Orders 14 Days",
      dataIndex: "orders14Days",
      key: "orders14Days",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold 14 Days",
      dataIndex: "unitsSold14Days",
      key: "unitsSold14Days",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New to Brand Sales 14 Days",
      dataIndex: "newToBrandSales14Days",
      key: "newToBrandSales14Days",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New to Brand Orders 14 Days",
      dataIndex: "newToBrandOrders14Days",
      key: "newToBrandOrders14Days",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New to Brand Purchases 14 Days",
      dataIndex: "newToBrandPurchases14Days",
      key: "newToBrandPurchases14Days",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New to Brand Units Sold 14 Days",
      dataIndex: "newToBrandUnitsSold14Days",
      key: "newToBrandUnitsSold14Days",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New to Brand Sales Percentage 14 Days",
      dataIndex: "newToBrandSalesPercentage14Days",
      key: "newToBrandSalesPercentage14Days",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New to Brand Orders Percentage 14 Days",
      dataIndex: "newToBrandOrdersPercentage14Days",
      key: "newToBrandOrdersPercentage14Days",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New to Brand Purchases Percentage 14 Days",
      dataIndex: "newToBrandPurchasesPercentage14Days",
      key: "newToBrandPurchasesPercentage14Days",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New to Brand Units Sold Percentage 14 Days",
      dataIndex: "newToBrandUnitsSoldPercentage14Days",
      key: "newToBrandUnitsSoldPercentage14Days",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Sales 14 Days",
      dataIndex: "sales14Days",
      key: "sales14Days",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Campaign Budget Currency Code",
      dataIndex: "campaignBudgetCurrencyCode",
      key: "campaignBudgetCurrencyCode",
    },

    {
      title: "Ad Group Name",
      dataIndex: "adGroupName",
      key: "adGroupName",
      width: 150,
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplaceID",
      key: "marketplaceID",
      width: 150,
    },
  ];

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `advertising/sb-purchased-product?page=${
        data?.page || page || 1
      }&per-page=${data?.pageSize || pageSize}&field_name=${
        data?.field_name || ""
      }&sort_by=${data?.sort_by || ""}&campaign_name=${
        data?.campaign_name || ""
      }&campaign_status=${data?.campaign_status || ""}&start_date=${
        data?.start_date || ""
      }&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);

      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setLoading(false);
      setTableLoading(false);
      setTotalPage(0);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(false);
    // getList({ ...filters, page: page, pageSize: pageSize, ...sortFilters });
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters, page: page, pageSize: pageSize });
  };

  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Sponsored Brand Purchases
          <InfoVideo
            data={locationData}
            title={"sb_purchases"}
            className={"ms-3"}
          />
          <Input
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            value={filters?.campaign_name}
            onChange={(e) => {
              setFilters({
                ...filters,
                campaign_name: e.target.value,
              });
            }}
            placeholder="Campaign Name"
            className="w-175px ms-3"
          />
          <Select
            placeholder="Campaign Status"
            className="w-150px ms-3"
            allowClear
            value={filters?.campaign_status}
            onChange={(e) => {
              const obj = {
                ...filters,
                campaign_status: e,
              };
              setFilters(obj);
              getList({
                ...obj,
                page: page,
                pageSize: pageSize,
                ...sortFilters,
              });
            }}
            options={[
              {
                label: "ENABLED",
                value: "ENABLED",
              },
              {
                label: "PAUSED",
                value: "PAUSED",
              },
            ]}
          />
        </div>

        <div className="card-toolbar">
          <DatePicker.RangePicker
            // allowClear
            presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              setFilters(obj);
              getList({ ...obj, ...sortFilters });
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />
          <ExportFile
            hideTooltip={filters?.start_date && filters?.end_date}
            title="SB Purchases"
            style={{ pointerEvents: "none", opacity: 0.4 }}
            api={`advertising/sb-purchased?start_date=${
              filters?.start_date || ""
            }&end_date=${filters?.end_date || ""}&is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&campaign_name=${
              filters?.campaign_name || ""
            }&campaign_status=${filters?.campaign_status || ""}`}
          />
        </div>
      </div>
      <div className="card-body  pt-0">
        {1 === 2 ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              scroll={{ x: "max-content" }}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              columns={columns?.map((d) => ({
                ...d,
                className: "minWidth-table",
                ...PropsFilter(d?.dataIndex),

                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SbPurchases;
