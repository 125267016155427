import { DatePicker, Empty, Input, Select, Table, message } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import { NumberWithCommas, RenderTable } from "../../../../config";
import ExportFile from "./export-file";
import { ErrorModal } from "../../../../components/upload-modals";
dayjs.extend(quarterOfYear);
const SbCampaigns = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [filters, setFilters] = useState({
    ad_format: null,
    campaign_name: null,
    campaign_status: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const columns = [
    {
      title: "Report Date",
      dataIndex: "report_date",
      key: "report_date",
    },
    {
      title: "Campaign Id",
      dataIndex: "campaign_id",
      key: "campaign_id",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 400,
    },
    {
      title: "Add To Cart",
      dataIndex: "add_to_cart",
      key: "add_to_cart",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Ad Format",
      dataIndex: "ad_format",
      key: "ad_format",
    },
    {
      title: "Add To Cart Clicks",
      dataIndex: "add_to_cart_clicks",
      key: "add_to_cart_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Add To Cart Rate (%)",
      dataIndex: "add_to_cart_rate",
      key: "add_to_cart_rate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Branded Searches",
      dataIndex: "branded_searches",
      key: "branded_searches",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Branded Searches Clicks",
      dataIndex: "branded_searches_clicks",
      key: "branded_searches_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Campaign Budget Amount ($)",
      dataIndex: "campaign_budget_amount",
      key: "campaign_budget_amount",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Campaign Budget Currency Code",
      dataIndex: "campaign_budget_currency_code",
      key: "campaign_budget_currency_code",
      align: "right",
      render: (e) => {
        return e;
      },
    },

    {
      title: "Campaign Budget Type",
      dataIndex: "campaign_budget_type",
      key: "campaign_budget_type",
    },

    {
      title: "Campaign Status",
      dataIndex: "campaign_status",
      key: "campaign_status",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cost ($)",
      dataIndex: "cost",
      key: "cost",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cost Type",
      dataIndex: "cost_type",
      key: "cost_type",

      render: (e) => {
        return e;
      },
    },
    {
      title: "Detail Page Views",
      dataIndex: "detail_page_views",
      key: "detail_page_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Detail Page Views Clicks",
      dataIndex: "detail_page_views_clicks",
      key: "detail_page_views_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "e CP Add To Cart (%)",
      dataIndex: "e_cp_add_to_cart",
      key: "e_cp_add_to_cart",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },

    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand Detail Page View Rate (%)",
      dataIndex: "new_to_brand_detail_page_view_rate",
      key: "new_to_brand_detail_page_view_rate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Detail Page Views",
      dataIndex: "new_to_brand_detail_page_views",
      key: "new_to_brand_detail_page_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand Detail Page Views Clicks",
      dataIndex: "new_to_brand_detail_page_views_clicks",
      key: "new_to_brand_detail_page_views_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand e CP Detail Page View ($)",
      dataIndex: "new_to_brand_ecp_detail_page_view",
      key: "new_to_brand_ecp_detail_page_view",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Purchases",
      dataIndex: "new_to_brand_purchases",
      key: "new_to_brand_purchases",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand Purchases Clicks",
      dataIndex: "new_to_brand_purchases_clicks",
      key: "new_to_brand_purchases_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand Purchases (%)",
      dataIndex: "new_to_brand_purchases_percentage",
      key: "new_to_brand_purchases_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Purchases Rate (%)",
      dataIndex: "new_to_brand_purchases_rate",
      key: "new_to_brand_purchases_rate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Sales ($)",
      dataIndex: "new_to_brand_sales",
      key: "new_to_brand_sales",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Sales Clicks ($)",
      dataIndex: "new_to_brand_sales_clicks",
      key: "new_to_brand_sales_clicks",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Sales (%)",
      dataIndex: "new_to_brand_sales_percentage",
      key: "new_to_brand_sales_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "New To Brand Units Sold",
      dataIndex: "new_to_brand_units_sold",
      key: "new_to_brand_units_sold",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand Units Sold Clicks",
      dataIndex: "new_to_brand_units_sold_clicks",
      key: "new_to_brand_units_sold_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "New To Brand Units Sold %",
      dataIndex: "new_to_brand_units_sold_percentage",
      key: "new_to_brand_units_sold_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases",
      dataIndex: "purchases",
      key: "purchases",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases Clicks",
      dataIndex: "purchases_clicks",
      key: "purchases_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases Promoted",
      dataIndex: "purchases_promoted",
      key: "purchases_promoted",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Sales ($)",
      dataIndex: "sales",
      key: "sales",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Sales Clicks ($)",
      dataIndex: "sales_clicks",
      key: "sales_clicks",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Sales Promoted ($)",
      dataIndex: "sales_promoted",
      key: "sales_promoted",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Top Of Search Impression Share (%)",
      dataIndex: "top_of_search_impression_share",
      key: "top_of_search_impression_share",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Units Sold",
      dataIndex: "units_sold",
      key: "units_sold",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Clicks",
      dataIndex: "units_sold_clicks",
      key: "units_sold_clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Video5 Second View Rate (%)",
      dataIndex: "video5_second_view_tate",
      key: "video5_second_view_tate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Video5 Second Views",
      dataIndex: "video5_second_views",
      key: "video5_second_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Video Complete Views",
      dataIndex: "video_complete_views",
      key: "video_complete_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Video First Quartile Views",
      dataIndex: "video_first_quartile_views",
      key: "video_first_quartile_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Video Midpoint Views",
      dataIndex: "video_midpoint_views",
      key: "video_midpoint_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Video Third Quartile Views",
      dataIndex: "video_third_quartile_views",
      key: "video_third_quartile_views",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Video Unmutes",
      dataIndex: "video_unmutes",
      key: "video_unmutes",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Viewability Rate (%)",
      dataIndex: "viewability_rate",
      key: "viewability_rate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Viewable Impressions",
      dataIndex: "viewable_impressions",
      key: "viewable_impressions",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "View Click Through Rate (%)",
      dataIndex: "view_click_through_rate",
      key: "view_click_through_rate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
  ];

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `advertising/sb-campaigns?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&ad_format=${data?.ad_format || filters?.ad_format || ""}&field_name=${
        data?.field_name || ""
      }&sort_by=${data?.sort_by || ""}&campaign_name=${
        data?.campaign_name || ""
      }&campaign_status=${data?.campaign_status || ""}&start_date=${
        data?.start_date || ""
      }&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTableLoading(false);
      setLoading(false);
      setTotalPage(0);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters, page: page, pageSize: pageSize, ...sortFilters });
    return () => {};
  }, [filters.ad_format]);

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters, page: page, pageSize: pageSize });
  };
  return (
    <div className="custom-ui card">
      <div className="card-header">
        <div className="card-title">
          Sponsored Brand Campaign
          <InfoVideo
            data={locationData}
            title={"sb_campaigns"}
            className={"ms-3"}
          />
          <Select
            value={filters?.ad_format}
            allowClear
            className="ms-5 w-160px"
            placeholder="Select Ad Format"
            onChange={(e) => {
              setPage(1);
              setFilters({
                ...filters,
                ad_format: e,
              });
            }}
            options={[
              {
                value: "productCollection",
                label: "Product Collection",
              },
              {
                value: "video",
                label: "Video",
              },
            ]}
          />
          <Input
            placeholder="Campaign Name"
            className="w-175px ms-3"
            onPressEnter={() => {
              getList({
                ...filters,
                page: page,
                pageSize: pageSize,
                ...sortFilters,
              });
            }}
            value={filters?.campaign_name}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      campaign_name: e.target.value,
                    },
                    page: page,
                    pageSize: pageSize,
                    ...sortFilters,
                  });
                }
                return {
                  ...prev,
                  campaign_name: e.target.value,
                };
              });
            }}
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                page: page,
                pageSize: pageSize,
                ...sortFilters,
              });
            }}
            className="btn btn-sm ms-3 btn-search"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select
            placeholder="Campaign Status"
            className="w-150px ms-3"
            value={filters?.campaign_status}
            onChange={(e) => {
              const obj = {
                ...filters,
                campaign_status: e,
              };
              setFilters(obj);
              getList({
                ...obj,
                page: page,
                pageSize: pageSize,
                ...sortFilters,
              });
            }}
            allowClear
            options={[
              {
                label: "ENABLED",
                value: "ENABLED",
              },
              {
                label: "PAUSED",
                value: "PAUSED",
              },
            ]}
          />
        </div>
        <div className="card-toolbar">
          <DatePicker.RangePicker
            // allowClear
            presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              setFilters(obj);
              getList({ ...obj, ...sortFilters });
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />
          <ExportFile
            hideTooltip={filters?.start_date && filters?.end_date}
            title="SB-Campaigns"
            api={`advertising/sb-campaigns?start_date=${
              filters?.start_date || ""
            }&end_date=${filters?.end_date || ""}&is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&campaign_name=${
              filters?.campaign_name || ""
            }&campaign_status=${filters?.campaign_status || ""}&ad_format=${
              filters?.ad_format || ""
            }`}
          />
        </div>
      </div>
      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty style={{ minHeight: "500px" }} />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              loading={tableLoading}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
              columns={columns?.map((d) => ({
                ...d,
                className: "minWidth-table",
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              pageSize: pageSize,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SbCampaigns;
