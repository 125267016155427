import { Carousel, message, Progress, Table, Typography } from "antd";
import React, { useContext, useEffect, useRef } from "react";

import { CheckCircleFilled, SyncOutlined } from "@ant-design/icons";
import { useState } from "react";
import { MakeApiCall } from "../../apis";
import { RenderTable } from "../../config";
import { Wrapper } from "./style";
import { debounce } from "lodash";
import { GlobalContext } from "../../common-context";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import Icons from "../icons";
import styled from "styled-components";

const TableWrapper = styled.div`
  position: relative;
  .table-container_ {
    position: relative;
    padding: 0px 15px 4px 0px;
    border-radius: 8px;
    height: 148px;
    overflow-y: hidden;
    display: block;
  }

  .scroll-icons {
    width: fit-content;
    right: -3px;

    position: absolute;
    display: grid;
    height: 100%;
    top: 0;
    align-content: space-between;
    right: 0;
    svg {
      cursor: pointer;
    }
  }
  .table-container_ thead {
    position: sticky;
    top: -0.7px;
    z-index: 1;
    background: #fafafa;
  }
  .data-table {
    width: 100%;
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-radius: 9px;
    overflow: auto;
    background: #e9e9e9;
  }

  .data-table th {
    text-align: left;
    padding: 10px;
    color: #666;
    font-weight: bold;
    background: #fafafa;
  }

  .data-table td {
    background-color: white;
    padding: 15px;
  }

  .data-table tr:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .data-table tr:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .status-cell {
    display: flex;
    align-items: center;
  }

  .status-text {
    margin: 0 10px;
    font-weight: bold;
  }

  .status-icon {
    font-size: 20px;
  }

  .status-icon.completed {
    color: #17b26a;
  }
  tr {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }
  .status-icon.syncing {
    color: #2196f3;
  }
`;

const CarouselView = () => {
  const contextValue = useContext(GlobalContext);
  const location = useLocation();
  const [list, setList] = useState([]);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [offsetHeight1, setOffsetHeight1] = useState(0);
  const [currentScroll1, setCurrentScroll1] = useState(0);

  const [totalLog, setTotalLog] = useState({});

  const getProgressList = async () => {
    const response = await MakeApiCall(`user/log-progress`, "get", null, true);

    if (response?.status) {
      setList(response?.data?.log_data || []);
      setTotalLog(response?.data?.total_log_data || {});
    } else {
      setList([]);
    }
  };

  useEffect(() => {
    getProgressList();
    return () => {};
  }, []);

  const scrollToTop = () => {
    const element = document.getElementById("tableView");
    if (element) {
      const Currently = currentScroll - 106 < 0 ? 0 : currentScroll - 106;
      setCurrentScroll(Currently);
      element.scrollTop = Currently;
    }
  };

  const scrollToBottom = () => {
    const element = document.getElementById("tableView");
    if (element) {
      const Currently =
        currentScroll + 106 > offsetHeight ? offsetHeight : currentScroll + 106;
      setCurrentScroll(Currently);
      element.scrollTop = Currently;
    }
  };
  const scrollToTop1 = () => {
    const element = document.getElementById("tableView1");
    if (element) {
      const Currently = currentScroll1 - 106 < 0 ? 0 : currentScroll1 - 106;
      setCurrentScroll1(Currently);
      element.scrollTop = Currently;
    }
  };

  const scrollToBottom1 = () => {
    const element = document.getElementById("tableView1");
    if (element) {
      const Currently =
        currentScroll + 106 > offsetHeight1
          ? offsetHeight1
          : currentScroll1 + 106;
      setCurrentScroll1(Currently);
      element.scrollTop = Currently;
    }
  };

  const tableList1 = list?.filter(
    (d) => d?.event_type !== "TIME_SERIES_CALCULATION"
  );
  const tableList2 = list?.filter(
    (d) => d?.event_type === "TIME_SERIES_CALCULATION"
  );

  useEffect(() => {
    const element = document.getElementById("tableView");
    const element1 = document.getElementById("tableView1");

    if (element) {
      setOffsetHeight(element.scrollHeight - element.offsetHeight);
      setCurrentScroll(element.scrollTop);
    }
    if (element1) {
      setOffsetHeight1(element1.scrollHeight - element1.offsetHeight);
      setCurrentScroll1(element1.scrollTop);
    }
    return () => {};
  }, [list]);

  const UpArrow = (
    <i
      onClick={scrollToTop}
      style={{
        opacity: Math.round(currentScroll) === 0 ? 0.5 : 1,
        cursorPointer:
          Math.round(currentScroll) === 0 ? "not-allowed" : "pointer",
      }}
      className="ki-duotone ki-up upArrow fs-3"
    />
  );
  const DownArrow = (
    <i
      onClick={scrollToBottom}
      style={{
        opacity: Math.round(currentScroll) === offsetHeight ? 0.5 : 1,
        cursorPointer:
          Math.round(currentScroll) === offsetHeight
            ? "not-allowed"
            : "pointer",
      }}
      className="ki-duotone downArrow ki-down  fs-3"
    />
  );
  const UpArrow1 = (
    <i
      onClick={scrollToTop1}
      style={{
        opacity: Math.round(currentScroll1) === 0 ? 0.5 : 1,
        cursorPointer:
          Math.round(currentScroll1) === 0 ? "not-allowed" : "pointer",
      }}
      className="ki-duotone ki-up upArrow fs-3"
    />
  );
  const DownArrow1 = (
    <i
      onClick={scrollToBottom1}
      style={{
        opacity: Math.round(currentScroll1) === offsetHeight1 ? 0.5 : 1,
        cursorPointer:
          Math.round(currentScroll1) === offsetHeight1
            ? "not-allowed"
            : "pointer",
      }}
      className="ki-duotone downArrow ki-down  fs-3"
    />
  );

  const columns = [
    {
      title: "Data Gathering Progress",
      align: "left",
      children: [
        {
          title: (
            <div className="d-flex align-items-center justify-content-between">
              <span>Data Source</span>
              <div
                style={{
                  display: tableList1?.length === 0 ? "none" : "grid",
                  justifyItems: "center",
                }}
              >
                {UpArrow}
                {DownArrow}
              </div>
            </div>
          ),
          width: "200px",
          dataIndex: "event_name",
          key: "event_name",
        },
        {
          title: "Status",
          width: "100px",
          dataIndex: "log_pect",
          key: "log_pect",
          render: (e) => {
            if (e == 0) {
              return <span>Pending</span>;
            }
            return <span>{e}%</span>;
          },
        },
        {
          title: "",
          dataIndex: "log_pect",
          width: "100px",
          align: "center",
          key: "log_pect",
          render: (e) => {
            return e === 100 ? "✅" : "🛑";
          },
        },
      ],
    },
  ];

  const ChangeStatusView = async () => {
    const response = await MakeApiCall(
      `user/update-log-status/${contextValue?.data?.user_?.id || ""}`,
      "put",
      {
        is_closed_log_process: 1,
      },
      true
    );

    if (response?.status) {
      contextValue.updateCommonGlobalVal({
        user_: {
          ...JSON.parse(localStorage.getItem("user")),
          is_closed_log_process: response?.data?.is_closed_log_process,
        },
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          is_closed_log_process: response?.data?.is_closed_log_process,
        })
      );

      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const [index, setIndex] = useState(0);
  const filteredList = list?.filter((d) => parseInt(d?.log_pect) !== 100);
  useEffect(() => {
    let timer;

    if (filteredList?.length > 0) {
      timer = setInterval(() => {
        setIndex((prevIndex) => {
          return prevIndex >= filteredList?.length - 1 ? 0 : prevIndex + 1;
        });
      }, 30000);
    }
    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(timer);
  }, [list]);

  const modules = [
    "resources",
    "spapi-callback",
    "system-parameters",
    "seller-profile",
    "profile",
    "request-support",
    "central-log",
    "pending",
    "data-scheduler",
  ];

  console.log(totalLog?.total_log_pect, "totalLog?.total_log_pect");

  if (
    modules?.includes(location?.pathname?.replace("/", "")) ||
    contextValue?.data?.user_?.is_closed_log_process === 1
  )
    return;
  return (
    <Wrapper
      className={`position-relative w-100 mb-5  ${
        list?.length === 0 ? "d-none" : ""
      }`}
    >
      <div className="card-view-progress mx-10 mt-5">
        <div>
          <div
            className=" mt-2 mb-2 position-relative  d-flex align-items-center justify-content-start"
            style={{
              background: "#FFF",
              borderRadius: "6px",
              border: "1px solid #17B26A",
              padding: "8px 0px 5px",
            }}
          >
            <div className="ms-3 me-3">
              <Icons type="progress-image" />
              {/* <img
                  style={{ width: "80px", objectFit: "contain" }}
                  src={`/assets/media/${
                    d?.log_pect === 100 ? "Completed.png" : "Inprogress.png"
                  }`} // Completed - Inprogress
                /> */}
            </div>

            <div className="w-100 " style={{ paddingRight: "1.25rem" }}>
              <Typography.Text strong>
                {Math.round(totalLog?.total_log_pect) === 100
                  ? "Initial data fetching and calculations are completed."
                  : "Initial data fetching and calculations are in progress."}
              </Typography.Text>
              <br />
              <Typography.Text>
                {Math.round(totalLog?.total_log_pect) === 100
                  ? "Your initial data gathering, and Pathfinder calculations are completed. You can now close this window."
                  : "Track your data fetching and calculations below. Keep this window open to monitor."}
              </Typography.Text>
              <Progress
                percent={Math.round(totalLog?.total_log_pect)}
                strokeColor="#17B26A"
                format={() =>
                  Math.round(totalLog?.total_log_pect) === 100 ? (
                    <Icon
                      icon="lets-icons:check-fill"
                      style={{ color: "#17B26A", fontSize: "16px" }}
                    />
                  ) : (
                    `${Math.round(totalLog?.total_log_pect) || 0}%`
                  )
                }
                status={
                  Math.round(totalLog?.total_log_pect) === 100
                    ? "success"
                    : "active"
                }
              />
              {/* <small style={{ color: "#009efb" }}>
                  <b>{contextValue?.data?.user_?.seller_name || "-"}</b>
                </small> */}
              {/* active */}
              {/* {d?.log_pect === 100 && (
                  <i
                    className="ki-duotone ki-cross-square fs-4 cursor-pointer"
                    onClick={() => {
                      setIndex((prev) => {
                        return index + 1;
                      });
                    }}
                    style={{
                      position: "absolute",
                      zIndex: 9,
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                )} */}
              {Math.round(totalLog?.total_log_pect) === 100 && (
                <span
                  onClick={() => {
                    ChangeStatusView();
                  }}
                  style={{
                    position: "absolute",
                    zIndex: 9,
                    top: "10px",
                    right: "10px",
                    zoom: "70%",
                  }}
                >
                  <Icons type="modal-cross-icon" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className={`${
              totalLog?.total_log_pect === 100
                ? "d-none"
                : "col-xxl-4 col-xl-12 col-lg-12 col-md-12 col-sm-12"
            } `}
          >
            <div className="d-grid mb-2 p-3" style={{ justifyItems: "center" }}>
              <span style={{ zoom: "90%" }} className="mt-4">
                <Icons type="progress-wait-image" />
              </span>
              <span
                className="mt-7"
                style={{
                  fontSize: "20px",
                  color: "#000000",
                  fontWeight: 600,
                }}
              >
                Currently in progress
              </span>
              <span style={{ color: "#5C5757", fontSize: "14px" }}>
                {filteredList?.[index]?.event_type !== "TIME_SERIES_CALCULATION"
                  ? "Fetching Data: "
                  : "Calculating Pathfinder:"}
                &nbsp;
                {filteredList?.[index]?.event_name || "-"}
              </span>
              <Progress
                percent={filteredList?.[index]?.log_pect}
                strokeColor="#17B26A"
                format={() =>
                  filteredList?.[index]?.log_pect === 100 ? (
                    <Icon
                      icon="lets-icons:check-fill"
                      style={{ color: "#17B26A", fontSize: "16px" }}
                    />
                  ) : (
                    `${filteredList?.[index]?.log_pect || 0}%`
                  )
                }
                status={
                  filteredList?.[index]?.log_pect === 100 ? "success" : "active"
                }
              />
            </div>
          </div>
          <div
            className={`${
              totalLog?.total_log_pect !== 100
                ? "col-xxl mt-3 col-xl-12 col-lg-12 col-md-12  col-sm-12"
                : "col-12"
            }`}
          >
            <div
              className="row me-0"
              style={{
                background: "#FFF",
                border: "1px solid #E9E9E9",
                borderRadius: 8,
                padding: 15,
              }}
            >
              <div className="col-xxl-6 col-xl-6 col-lg-12 col-sm-12">
                <div className="row ms-1 mb-2" style={{ fontWeight: 600 }}>
                  Data Gathering Process
                </div>
                <TableWrapper>
                  <div id="tableView" className="table-container_">
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>Data Sources</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableList1.map((item, index) => (
                          <tr key={index}>
                            <td>{item.event_name}</td>
                            <td className="status-cell">
                              <Progress
                                percent={item.log_pect}
                                strokeColor="#4CAF50"
                                trailColor="#E0E0E0"
                                strokeWidth={10}
                                showInfo={false}
                              />
                              <span className="status-text">
                                {item.log_pect}%
                              </span>
                              {parseInt(item.log_pect) === 100 ? (
                                <CheckCircleFilled className="status-icon completed" />
                              ) : (
                                <Icons type="progress-table" />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="scroll-icons">
                    <span
                      onClick={scrollToTop}
                      style={{
                        opacity: Math.round(currentScroll) === 0 ? 0.5 : 1,
                        cursorPointer:
                          Math.round(currentScroll) === 0
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <Icons type="table-up-arrow" />
                    </span>
                    <span
                      onClick={scrollToBottom}
                      style={{
                        opacity:
                          Math.round(currentScroll) === offsetHeight ? 0.5 : 1,
                        cursorPointer:
                          Math.round(currentScroll) === offsetHeight
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <Icons type="table-down-arrow" />
                    </span>
                  </div>
                </TableWrapper>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-12 col-sm-12">
                <div className="row ms-1 mb-2" style={{ fontWeight: 600 }}>
                  Pathfinder Calculations Process
                </div>
                <TableWrapper>
                  <div id="tableView1" className="table-container_">
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>Metric Group</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableList2.map((item, index) => (
                          <tr key={index}>
                            <td>{item.event_name}</td>
                            <td className="status-cell">
                              <Progress
                                percent={item.log_pect}
                                strokeColor="#4CAF50"
                                trailColor="#E0E0E0"
                                strokeWidth={10}
                                showInfo={false}
                              />
                              <span className="status-text">
                                {item.log_pect}%
                              </span>
                              {parseInt(item.log_pect) === 100 ? (
                                <CheckCircleFilled className="status-icon completed" />
                              ) : (
                                <Icons type="progress-table" />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="scroll-icons">
                    <span
                      onClick={scrollToTop1}
                      style={{
                        opacity: Math.round(currentScroll1) === 0 ? 0.5 : 1,
                        cursorPointer:
                          Math.round(currentScroll1) === 0
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <Icons type="table-up-arrow" />
                    </span>
                    <span
                      onClick={scrollToBottom1}
                      style={{
                        opacity:
                          Math.round(currentScroll1) === offsetHeight1
                            ? 0.5
                            : 1,
                        cursorPointer:
                          Math.round(currentScroll1) === offsetHeight1
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <Icons type="table-down-arrow" />
                    </span>
                  </div>
                </TableWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none card p-5">
          <div className="row">
            <div
              className="col-xxl-6 col-xl-6 col-lg-12"
              id="tableView3"
              style={{ maxHeight: "231px", overflow: "auto" }}
            >
              <Table
                columns={columns?.map((d) => ({
                  ...d,
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                pagination={false}
                size="small"
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                scroll={{ x: "max-content" }}
                dataSource={tableList1}
              />
            </div>
            <div
              className="col-xxl-6 col-xl-6 col-lg-12"
              id="tableView1"
              style={{ maxHeight: "231px", overflow: "auto" }}
            >
              <Table
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                columns={[
                  {
                    title: "Pathfinder Calculations Progress",
                    align: "left",
                    children: [
                      {
                        // title: "Metric Group",
                        title: (
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Metric Group</span>
                            <div
                              style={{
                                display:
                                  tableList2?.length === 0 ? "none" : "grid",
                                justifyItems: "center",
                              }}
                            >
                              {UpArrow1}
                              {DownArrow1}
                            </div>
                          </div>
                        ),

                        dataIndex: "event_name",
                        key: "event_name",
                        width: "200px",
                      },
                      {
                        title: "Status",
                        dataIndex: "log_pect",
                        width: "100px",
                        key: "log_pect",
                        render: (e) => {
                          if (e == 0) {
                            return <span>Pending</span>;
                          }
                          return <span>{e}%</span>;
                        },
                      },
                      {
                        title: "",
                        align: "center",
                        width: "100px",
                        dataIndex: "log_pect",

                        key: "log_pect",
                        render: (e) => {
                          return e === 100 ? "✅" : "🛑";
                        },
                      },
                    ],
                  },
                ]?.map((d) => ({
                  ...d,
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                pagination={false}
                size="small"
                scroll={{ x: "max-content" }}
                dataSource={tableList2}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CarouselView;
