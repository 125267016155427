import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Form, Input, Tabs, Upload, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import { ENDPOINT } from "../../../config";
import { Wrapper } from "./style";
import InfoVideo from "../../../components/header-icons";
import CredentialsError from "../../credential-page";

const SettingProfile = ({ menusList, pageTitle }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const contextValue = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState(
    contextValue?.data?.user_?.photo
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: `${contextValue?.data?.user_?.photo}`,
          },
        ]?.filter((d) => d?.url !== "null")
      : []
  );
  const [profileData, setProfileData] = useState({});

  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=7`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  console.log(contextValue?.data?.user_?.seller_id, "contextValue?.data");

  const getProfile = async () => {
    const response = await MakeApiCall(
      `seller-user/profile/${contextValue?.data?.user_?.seller_user_id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setProfileData(response?.data);
      setFileList(
        [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: `${response?.data?.photo}`,
          },
        ]?.filter((d) => d?.url !== "null")
      );
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };
  const updateProfile = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `seller-user/update-profile/${contextValue?.data?.user_?.seller_id}`,
      "put",
      { ...data, seller_id: contextValue?.data?.user_?.seller_id },
      true
    );

    if (response?.status) {
      contextValue.updateCommonGlobalVal({
        ...JSON.parse(localStorage.getItem("user")),
        ...response?.data,
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          ...response?.data,
        })
      );
      setProfileData({ ...profileData, ...response?.data });

      setLoading(false);
      history.push("/dashboard");
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };
  const resetPassword = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `seller-user/change-password/${contextValue?.data?.user_?.seller_user_id}`,
      "put",
      {
        new_password: data,
      },
      true
    );
    if (response?.status) {
      message.destroy();
      form.resetFields();
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getProfile();

    return () => {};
  }, []);

  const getBase64 = (img, callback) => {
    if (img instanceof Blob) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.onerror = function (e) {
        console.error("Error reading file:", e);
      };
      reader.readAsDataURL(img || "");
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const onFinish = (values) => {
    resetPassword(values?.password);
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setFileList([info.file]);
    }

    if (info.file.status === "done") {
      console.log(info, "info");

      setFileList([info.file]);
      setProfileData({ ...profileData, ...info?.file?.response?.data });
      contextValue.updateCommonGlobalVal({
        user_: {
          ...JSON.parse(localStorage.getItem("user")),
          ...info?.file?.response?.data,
        },
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          ...info?.file?.response?.data,
        })
      );

      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      setFileList([]);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const items = [
    {
      key: "1",
      label: <span className="px-7">Settings</span>,
      children: (
        <div className="card-body">
          <div className="form-horizontal">
            <input
              type="hidden"
              name="c_is_active"
              defaultValue="d47b2215fdf1f943253101aa177608b4"
            />
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Full Name"
              >
                Full Name{" "}
              </label>
              <div className="col-sm-5 ">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="form_first_name"
                  placeholder="First Name"
                  name="first_name"
                  value={profileData?.first_name}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      first_name: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
              <div className="col-sm-4 ">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="form_last_name"
                  placeholder="Last Name"
                  name="last_name"
                  value={profileData?.last_name}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      last_name: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Email"
              >
                Email
              </label>
              <div className="col-sm-9 ">
                <input
                  required
                  type="email"
                  className="form-control"
                  id="form_email"
                  placeholder="email"
                  name="email"
                  value={profileData?.email}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      email: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            {/* phone */}
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="phone"
              >
                Mobile Number
              </label>
              <div className="col-sm-9 ">
                <input
                  required
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Mobile Number"
                  name="phone"
                  value={profileData?.contact_no}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      contact_no: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Image"
              >
                Image
              </label>
              <div style={{ marginTop: "10px" }} className="col-sm-9 ">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  name="SellerUser[photo]"
                  action={
                    ENDPOINT +
                    `seller-user/${contextValue?.data?.user_?.seller_user_id}/update-image`
                  }
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  }}
                  onChange={handleChange}
                  onRemove={() => setFileList([])}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Address Info"
              >
                Address{" "}
              </label>
              <div className="col-sm-9 ">
                <input
                  required
                  type="text"
                  className="form-control seller-require"
                  placeholder="Address1"
                  id="form_address1"
                  name="user_address1"
                  value={profileData?.address1}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      address1: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row ">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Address Info"
              ></label>
              <div className="col-sm-9">
                <input
                  required
                  type="text"
                  className="form-control"
                  id="form_address2"
                  placeholder="Address2"
                  name="user_address2"
                  value={profileData?.address2}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      address2: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row ">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Address Info"
              ></label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="form_address3"
                  placeholder="Address3"
                  name="user_address3"
                  value={profileData?.address3}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      address3: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Address Info"
              >
                City
              </label>
              <div className="col-sm-9 ">
                <input
                  required
                  type="text"
                  className="form-control seller-require"
                  placeholder="City"
                  id="form_city"
                  name="user_city"
                  value={profileData?.city}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      city: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Address Info"
              >
                State/Zip{" "}
              </label>
              <div className="col-sm-5 ">
                <input
                  required
                  type="text"
                  className="form-control seller-require"
                  placeholder="State"
                  id="form_state"
                  name="user_state"
                  value={profileData?.state}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      state: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
              <div className="col-sm-4 ">
                <input
                  required
                  type="text"
                  className="form-control seller-require"
                  placeholder="Zip"
                  id="form_zip"
                  name="user_zip"
                  value={profileData?.zip_code}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      zip_code: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-3 text-right control-label col-form-label"
                htmlFor="Address Info"
              >
                Country{" "}
              </label>
              <div className="col-sm-9 ">
                <input
                  required
                  type="text"
                  className="form-control seller-require"
                  placeholder="Country"
                  id="form_country"
                  name="user_country"
                  value={profileData?.country}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      country: e.target.value,
                    });
                  }}
                />
                <small className="form-control-feedback"></small>
              </div>
            </div>
            <div className="form-group m-b-0">
              <div className="offset-sm-3 col-sm-9">
                <Button
                  onClick={() => {
                    const {
                      first_name,
                      last_name,
                      email,
                      contact_no,
                      address1,
                      address2,
                      address3,
                      city,
                      state,
                      zip_code,
                      country,
                    } = profileData;

                    updateProfile({
                      first_name,
                      last_name,
                      email,
                      contact_no,
                      address1,
                      address2,
                      address3,
                      city,
                      state,
                      zip_code,
                      country,
                    });
                  }}
                  type="primary"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",

      label: <span className="px-7">Password</span>,
      children: (
        <div className="card-body">
          <Form
            form={form}
            onFinish={onFinish}
            // onValuesChange={onValuesChange}
            className="form-horizontal"
            // method="post"
            // action="https://members.app-sip.com/user/change_password"
            // acceptCharset="utf-8"
          >
            <input
              type="hidden"
              name="c_is_active"
              value="d47b2215fdf1f943253101aa177608b4"
            />

            <Form.Item
              label="Password"
              name="password"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item
              label="Repeat Password"
              name="repeatPassword"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Repeat Password" type="password" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];

  const { lws, sp } = contextValue?.data?.credentialsStatus;

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "profile")?.is_restricted === 1
  ) {
    return <CredentialsError type="profile-view" />;
  }
  // profile-view
  return (
    <Wrapper
      id="kt_content_container"
      className="container-fluid position-relative"
    >
      <div className="row mt-3">
        {/* Column */}
        <div className="col-lg-4 col-xlg-3 col-md-5">
          <div className="card">
            {localStorage.getItem("admin") && (
              <div className="card-header px-5 py-0">
                <div className="card-title">
                  {pageTitle}
                  <InfoVideo
                    data={locationData}
                    title={"my_profile"}
                    className={"ms-3"}
                  />
                </div>
              </div>
            )}
            <div className="card-body">
              <center className="m-t-30">
                <img
                  className="img-circle"
                  width={150}
                  height={150}
                  style={{ objectFit: "contain" }}
                  src={profileData?.photo}
                  onError={(e) => {
                    e.target.src = "/assets/media/300-2.jpg";
                  }}
                />
                <h4 className="card-title m-t-10">
                  {profileData?.first_name} {profileData?.last_name}
                </h4>
                <h6 className="card-subtitle">Primary User</h6>
              </center>
            </div>
            <div>
              <hr />
            </div>
            <div className="card-body">
              <small className="text-muted">Email address </small>
              <h6>{profileData?.email}</h6>
              <small className="text-muted p-t-30 db">Amazon Seller ID</small>
              <h6>{profileData?.amazon_seller_id || "-"}</h6>
              <small className="text-muted p-t-30 db">Address</small>
              <h6>
                {profileData?.address1 || "-"}, {profileData?.address2 || "-"},{" "}
                {profileData?.address3 || "-"}{" "}
              </h6>
              <small className="text-muted p-t-30 db">Created At</small>
              <h6>
                {" "}
                {moment(new Date(profileData?.created_at * 1000)).format(
                  "DD/MM/YYYY"
                )}{" "}
              </h6>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-xlg-9 col-md-7">
          <div className="card">
            <Tabs
              defaultActiveKey={
                location?.search?.includes("change-password") ? "2" : "1"
              }
              items={items}
            />
          </div>
        </div>
        {/* Column */}
      </div>
    </Wrapper>
  );
};

export default SettingProfile;
