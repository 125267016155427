import { createGlobalStyle } from "styled-components";

const AppStyleWrapper = createGlobalStyle`
body {
  font-family: 'Rubik', sans-serif;
}
.ant-drawer-header-title {
    flex-direction: row-reverse;
}
.ant-badge-count {
  box-shadow: none !important;
  
}
.mobile-number {
  .ant-select-selection-item {
    .country_name {
      display: none !important;
    }
  }
  .ant-select-dropdown {
    width: 448px !important;
    .ant-select-item-option-content {
      display: flex;
      justify-content: flex-start;
  
    }
  }
}
.tox-notification {
  display: none !important;
  visibility: hidden !important;
}
.authFadeInTop {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInTop;
    z-index : 99
  }
  
  @keyframes authFadeInTop {
    from {
      opacity: 0;
      transform: translateY(25%);
    }
    to {
      opacity: 1;
    }
  }
  .ant-table-thead {

    .ant-table-cell {
      background: lightyellow !important;
    }
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .even-row {
    background-color: #f0f0f0; /* or any other color */
  }
  .actionIcon {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .odd-row {
    background-color: #ffffff; /* or any other color */
  }
  .custom-date {
    border: none;
    background: #E4F3FF;
    input {
      color: #1E96D3;
      font-weight: 500;
    }
  }
  .config-checkbox {
    .ant-checkbox {
      transform: scale(1.4);
      margin-left: 3px;
    }
    .ant-checkbox-inner {
      background-color: #f1f1f2 !important;
      border: 1px solid #d9d9d900 !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #049ef7!important;
      }
    }
  }



  .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    background: lightyellow !important;
    color: #202020 !important;
  }

  .odd-row-new {
    background: #ebebeb !important;
    
  }
  .even-row-new {
    
    background: #fff !important;
  }
  .selected-row-financial .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #fafafa00 !important;
  }
 .ant-tooltip-inner {
  background-color: rgb(255 255 255 / 100%) !important;
  color: #000 !important;
 }
 .ant-tooltip .ant-tooltip-arrow:before {
  background: rgb(255 255 255 / 85%) !important;
 }
 .primaryHeading2 {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
}
.why-text {
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.content-layout {
  list-style-image: url(https://members.app-sip.com/assets/image/checkmark.gif);
  line-height: 1.5rem;
}
.amazon-logo {
  padding-left: 20px;
  margin-bottom: -25px;
  img {
    vertical-align: middle;
    border-style: none;
}
}
.connect-button {
  background-color: #80CC23;
  padding: 4px;
  margin-left: 32px;
  color: #fff;
  border: 2px solid #80CC23;
  border-radius: 2px;
}
.Connected{
  background: #efffdb;
  color: #759e17;
  font-weight: 700;
  display: flex;
  width: fit-content;
    padding: 3px 11px 3px 0px;
    border-radius: 5px;
  align-items: center;
  .dot{
    width: 15px;
    height: 15px;
    border-radius: 30px;
    background: #efffdb;
    border: 2px solid #759f17;
    margin: 0px 10px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    .dot-i{
      width: 6px;
      height: 6px;
      background: #759f17;
      border-radius: 100px;
    }
  }
}
.Disconnect{
  background: #ffdbdb;
  color: #9e1717;
  font-weight: 700;
  display: flex;
  width: fit-content;
    padding: 3px 11px 3px 0px;
    border-radius: 5px;
  align-items: center;
  .dot{
    width: 15px;
    height: 15px;
    border-radius: 30px;
    background: #ffdbdb;
    border: 2px solid #9e1717;
    margin: 0px 10px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    .dot-i{
      width: 6px;
      height: 6px;
      background: #9e1717;
      border-radius: 100px;
    }
  }
}

.video-list {
  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 400px);
  overflow: auto;
  gap: 9px;
}
.selected-videos {
  background: #1E96D3 !important;
  border: 1px solid #1E96D3 !important;

  .video-title {
  
  color: #FFF !important;
}

.video-description {
  
  color: #FFF !important;
}
}
.video-item {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid #EFEBEB;
  background: #FFFFFF;
  border-radius: 8px;
  padding-top: 21px;
  
  justify-content: space-between;
  cursor: pointer;
  transition: 0.6s;
  &:hover{
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

.video-thumbnail {
  
  height: 53px;
  width: 71px;
  object-fit: cover;
}

.video-info {
  flex-grow: 1; /* Allow video info to fill the available space */
}

.video-title {
  margin: 0; /* Remove default margin */
  font-size: 14px; /* Example font size */
  color: #000000;
}

.video-description {
  margin-top: 5px; /* Space between title and description */
  font-size: 12px; /* Example font size */
  color: #585656;
}
.btn.btn-info:hover:not(.btn-active), .btn.btn-primary:hover:not(.btn-active) {
  color: #fff;
  background-color: #0086d5 !important;
  border-color: #007ec8 !important;
}
// .apexcharts-tooltip-text-y-label{
//   font-size: 1.275rem !important;
//   color: #071437 !important;
//   font-weight: 700 !important;
// }
.ant-checkbox-group {
  .apexcharts-tooltip-text-y-label {
    font-weight: 500;
    font-size: 14px;
  }
}
  .DndLiveRegion-9 {
      display: none !important; }
.ellipsis3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
}
.custom-ui {

  .ant-table-thead .ant-table-cell {
    background: lightyellow !important;
  }
  .even-row {
    background-color: #f8f9fa; /* Color for even rows */
  }

  .odd-row {
    background-color: #ffffff; /* Color for odd rows */
  }
  .ant-table-wrapper .ant-table-thead >tr>th {
    font-weight: 500 !important;
  }
  th {
    // border: 1px solid #e9ecef !important;
  }
  .ant-table-wrapper .ant-table {
    font-size: 13px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: lightyellow !important;
  }
  .ant-table-tbody > .ant-table-row:hover {
    .ant-table-cell {
      background-color: #e6f7ff !important;
    }
  }

  .ant-table,
  .ant-table-cell {
    border-radius: 0 !important;
  }

  .ant-table-thead > tr > th {
    border-radius: 0 !important;
  }

  .ant-table-tbody > tr > td {
    border-radius: 0 !important;
  }

}
.btn-primary {
  border-radius: 0.25rem !important;
  color: #fff !important;
  background-color: #075ad7 !important;
  border-color: #075ad7 !important;
  font-size: small !important;
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff !important;
    background-color: #0086d5 !important;
    border-color: #007ec8 !important;
}
.header-brand {
  background: white !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .col-form-label {
  color: #6c757d !important;
}
.apexcharts-xaxistooltip.apexcharts-theme-light {
  background: white !important;
}
.ant-menu .ant-menu-item .ant-menu-item-icon >*, .ant-menu .ant-menu-submenu-title .ant-menu-item-icon >* {
  line-height: 0 !important;
}
.text-light {
  color: var(--bs-text-light) !important;
  svg {
    fill: var(--bs-text-light) !important;
  }
}



.add-btn {
  cursor: pointer !important;
  color: #fff !important;
  background-color: #3b71b8;
  
  font-weight: 500 !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 8.25px 0.75rem !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  display: flex !important;
  align-items: center !important;
  height: 35px !important;
  border-radius: 4px !important;
  border: thin solid #7460ee  !important;
  outline: 0 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  &:active {
    color: #fff !important;
    background-color: #4c32e9 !important;
    border-color: #4226e8 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(137, 120, 241, 0.5) !important;
    outline: none !important;
  }
  &:hover {
    color: #fff !important;
    background-color: #3363a2 !important;
    border-color: #4c32e9 !important;
    text-decoration: none !important;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(137, 120, 241, 0.5);
    color: #fff;
    background-color: #4c32e9;
    border-color: #4226e8;
  }
}

.ant-breadcrumb {
  li > span > a, li {
    font-weight: 500 !important;
    color: #009efb !important;
    text-decoration: none !important;
    background-color: transparent !important;
  }
  li:last-child, .ant-breadcrumb-separator {
    color: #6c757d !important;
    font-weight: 400 !important;
  }
}
.card {
  .card-body {
    border-bottom: none !important;
  }
  .card-footer {
    border-top: none !important;
  }
}
.btn-search {
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  background-color: #009efb !important;
  border-color: #009efb !important;
}
.btn-red {
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  background-color: #f62d51 !important;
  border-color: #f62d51 !important;
}
.inactive-seller{
  background-color: red;
  color: #fff;
  padding: 5px 7px;
  border-radius: 10px;
  cursor: pointer;
}
.active-seller{
  background-color: green;
  color: #fff;
  padding: 5px 7px;
  border-radius: 10px;
  cursor: pointer;
}
.action-button {

}
.hbspt-form {
  display: none;
}

 


//  .ant-table-sticky-holder {
//   table {
//     width: auto !important;
//   }
//  }

.ant-popover .ant-popover-title {
  color: #6c757d !important;
}
 




/* Show new content using the ::after pseudo-element */
th[aria-label="empty cell"]::after {
  content: "Wk#";
}
.justify-items-center {
  justify-items: center !important;
}
.ant-modal-confirm-body-wrapper, .ant-modal-confirm-paragraph, .ant-modal-confirm-btns {
  .ant-modal-confirm-body {
    display: grid;
    justify-items: center;
  }
  .ant-modal-confirm-paragraph {
    margin-top: 20px;
  }

}
.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-paragraph {
    display: grid !important;
    justify-items: center !important;
  }
  .ant-modal-confirm-btns {
    display: flex !important;
    justify-content: center !important;
  }
}

#registrationForm_coupon_code[value=""]
~ .ant-input-suffix
.ant-form-item-feedback-icon {
display: none !important;
}
.user-table-list {
.ant-table-thead .ant-table-cell {
    background: #fafafa !important;
}
}



.ant-pagination-item-active {
  background-color: #1e98d3 !important;
    border-color: transparent !important;
    color: #FFF !important;
}
    .ant-pagination-options {
        position: absolute;
    left: 0;
    margin-left: 0px !important;
    }

  .PCA-checked-custom {
  .ant-checkbox-inner {
    transform: scale(1.3);
  }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
    background: #00B660;
    border-color: #00B660;
  
    &:after {
    border-color: rgb(255 255 255);
}
}
    }
  }


* {
// scrollbar-color: transparent transparent !important;
}
 .scroll-show {
 .ant-table-body {
  scrollbar-color: #969696 transparent !important;
 }
  scrollbar-color: #969696 transparent !important;
}
   .hide-show {
 .ant-table-body {
  scrollbar-color: transparent transparent !important;
 }
  scrollbar-color: transparent transparent !important;
}


.ant-table-sticky-scroll-bar {
  display: none !important;
}
  .selected-row-financial {
    .ant-table-body {
      scrollbar-color: #969696 transparent !important;
    }
  }
    .minWidth-table {
      min-width: 120px;
    }
`;

export default AppStyleWrapper;
