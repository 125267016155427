import { Button, Checkbox, Drawer, Spin, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";

const Config = (props) => {
  const {
    onClose,
    open,
    data,
    metricSettingLoading,
    metricSettingList,
    onSave,
  } = props;

  const [checkedMetrics, setCheckedMetrics] = useState([]);

  useEffect(() => {
    setCheckedMetrics(metricSettingList);
    return () => {};
  }, []);

  const onChange = (checkedValues) => {
    setCheckedMetrics(
      checkedMetrics?.map((d) => ({
        ...d,
        status: checkedValues?.includes(d?.metric_id) ? "1" : "0",
      }))
    );
  };

  return (
    <Drawer
      title="Choose Metrics"
      placement="right"
      onClose={onClose}
      closeIcon={
        <i
          onClick={() => onClose()}
          className="ki-outline ki-cross-square fs-2"
        />
      }
      footer={
        <div className="card-footer  py-3 justify-content-start d-flex">
          <div className="me-3">
            <Button
              size="large"
              onClick={() => {
                if (checkedMetrics?.filter((d) => d?.status == 1)?.length > 8) {
                  message.destroy();
                  message.warning("Select only 8 cards");
                  return;
                }

                onSave(checkedMetrics);
              }}
              className="btn btn-primary fs-7"
            >
              Apply
            </Button>
          </div>
          <div>
            <button
              onClick={() => onClose()}
              className="btn btn-light-primary fs-7"
            >
              Close
            </button>
          </div>

          {/*end::Dismiss button*/}
        </div>
      }
      open={open}
    >
      <div className="card-body hover-scroll-overlay-y ">
        {metricSettingLoading && (
          <div
            style={{
              height: "calc(100vh - 150px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        )}
        {metricSettingLoading ? (
          <></>
        ) : data?.length === 0 ? (
          <Checkbox.Group
            className="d-grid config-checkbox"
            onChange={onChange}
            value={(checkedMetrics || [])
              .filter((d) => parseInt(d?.status) === 1)
              .map((d) => d?.metric_id)
              .filter((d) => d)}
          >
            {metricSettingList.map((metric, i) => (
              <>
                <Checkbox
                  className="mb-2"
                  key={i}
                  value={
                    metric?.master_metric_status &&
                    parseInt(metric?.master_metric_status) === 0
                      ? false
                      : metric.metric_id
                  }
                  disabled={
                    metric?.master_metric_status &&
                    parseInt(metric?.master_metric_status) === 0
                  }
                >
                  <div className="d-flex">
                    <Tooltip
                      placement="right"
                      title={
                        metric?.master_metric_status &&
                        parseInt(metric?.master_metric_status) === 0
                          ? metric.metric_name
                          : null
                      }
                    >
                      <div
                        style={{
                          width:
                            metric?.master_metric_status &&
                            parseInt(metric?.master_metric_status) === 0
                              ? "95px"
                              : "250px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginLeft: "11px",
                        }}
                      >
                        {metric.metric_name}
                      </div>
                    </Tooltip>
                    &nbsp;&nbsp;
                    {metric?.master_metric_status &&
                      parseInt(metric?.master_metric_status) === 0 && (
                        <>
                          <b
                            className="position-absolute "
                            style={{ right: 0 }}
                          >
                            (Currently Unavailable)
                          </b>
                        </>
                      )}
                  </div>
                </Checkbox>
                <div className="separator separator-dashed my-3" />
              </>
            ))}
          </Checkbox.Group>
        ) : (
          data?.map((d, i) => (
            <>
              <div
                key={i}
                className="form-check form-check-custom form-check-solid"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  // checked
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label fw-bold text-dark fs-6"
                  htmlFor="flexCheckDefault"
                >
                  {d}
                </label>
              </div>
              <div className="separator separator-dashed my-3" />
            </>
          ))
        )}
      </div>
    </Drawer>
  );
};

export default Config;
