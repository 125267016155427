import { Image, Input, Modal, Popconfirm, Table, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import Pagination from "../../../components/pagination";
import { TableLoading } from "../../../components/table-animation";
import {
  configModal,
  DeleteIcon,
  EditIcon,
  numberformat,
  RenderTable,
} from "../../../config";
import { Wrapper } from "./style";

import SellerProfileAdmin from "./seller profile";
import ArchiveUsers from "./archive.users";

const ManageUser = () => {
  const location = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [inviteEmail, setInviteEmail] = useState({ email: null });

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [deleteReload, setDeleteReload] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const [searchKey, setSearchKey] = useState("");

  const [addNew, setAddNew] = useState(false);

  const contextValue = useContext(GlobalContext);
  const history = useHistory();

  const getUser = async (data) => {
    const response = await MakeApiCall(
      `seller-user?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&search_key=${data?.searchKey || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setUserList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setUserList([]);
    }
  };
  const deleteUser = async (id) => {
    const response = await MakeApiCall(
      `seller-user/archived/${id}`,
      "delete",
      null,
      true
    );
    if (response?.status) {
      message.success(response?.message);
      setLoading(true);
      setDeleteReload(!deleteReload);
      getUser({ ...sortFilters, searchKey: searchKey });
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  const switchUser = async (value, _, page) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `seller-user/switch-seller/${value}?email=${contextValue?.data?.user_?.email}`,
      "get",
      null,
      true
    );
    message.destroy();
    if (response?.status) {
      localStorage.setItem(
        "admin",
        JSON.stringify({
          auth_token: localStorage.getItem("token"),
          user_data: JSON.parse(localStorage.getItem("user")),
          credentials: response?.data?.credentials,
        })
      );
      localStorage.setItem(
        "credentials",
        JSON.stringify(response?.data?.credentials)
      );
      localStorage.setItem("user", JSON.stringify(response?.data?.user_data));
      localStorage.setItem("token", response?.data?.auth_token);
      localStorage.setItem("user-type", response?.data?.user_data?.user_type);

      message.destroy();
      message.success(response?.message);

      contextValue?.updateCommonGlobalVal({
        user_: response?.data?.user_data,
        credentials: response?.data?.credentials,
      });
      history.push(page);
    } else {
      message.warning(response?.message);
    }
  };

  const messageView = async (title, message) => {
    const confirmed = await modal.warning(configModal(title, message));
  };
  const InviteUser = async () => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `seller-user/invite-seller`,
      "post",
      inviteEmail,
      true
    );
    message.destroy();
    if (response?.status) {
      setInviteEmail({ email: null });
      message.success(response?.data?.email_message || response?.message);
      history.push(`/manage-seller`);
    } else {
      messageView("Warning", response?.message);
    }
  };
  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getUser({ ...obj, searchKey: searchKey });
  };

  useEffect(() => {
    setLoading(true);
    getUser();
  }, []);

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{i + 1}</span>;
      },
    },
    {
      title: "Seller Name",
      key: "seller_name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
      align: "left",
      render: (e) => {
        return <span>{e}</span>;
      },
    },
    {
      title: "User Name",
      align: "left",
      filterIndex: "name",
      render: (d) => {
        return (
          <div className="d-flex align-items-center">
            {d?.photo ? (
              <Image src={d?.photo} width={45} height={45} />
            ) : (
              <div className="symbol symbol-circle symbol-50px overflow-hidden ">
                <a href>
                  <div
                    className="symbol-label fs-3 fw-bolder bg-light-danger text-danger"
                    style={{ textTransform: "capitalize" }}
                  >
                    {d?.name?.[0]}
                  </div>
                </a>
              </div>
            )}
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column ms-3">
              <a
                href
                className="text-gray-800 text-hover-primary mb-1 fw-bolder"
              >
                {d?.name || "-"}
              </a>
              <span>{d?.email || "-"}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Select Seller",

      render: (d) => {
        return (
          <div class=" pe-2 ">
            <a
              onClick={() =>
                switchUser(d?.seller_user_id, d?.email, "/dashboard")
              }
              className=" add-btn fs-7 fw-bolder"
              style={{ width: "fit-content" }}
            >
              <i
                className="ki-outline ki-arrow-right-left fs-3  me-2"
                style={{ color: "#FFF" }}
              ></i>{" "}
              Select Seller
            </a>
          </div>
        );
      },
    },
    {
      title: "Pending Reports",
      key: "pending_reports",
      dataIndex: "pending_reports",
      filterIndex: "pending_reports",
      align: "right",
      render: (e, d) => {
        return (
          <a
            style={{ color: "#009ffb" }}
            onClick={() =>
              switchUser(
                d?.seller_user_id,
                d?.email,
                "/central-log?event_status=PENDING"
              )
            }
          >
            {numberformat(e)}
          </a>
        );
      },
    },
    {
      title: "Created At (ET)",
      align: "left",
      filterIndex: "created_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Updated At (ET)",
      align: "left",
      filterIndex: "updated_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.updated_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text) => {
        // console.log('');

        return (
          <div className="   d-flex justify-content-center  ">
            <div
              onClick={() => {
                history.push(`/manage-seller?user-id=${text?.seller_id}`);
                // setSelectedRows(text)
              }}
              className="me-2"
            >
              {EditIcon}
            </div>
            {text?.id !== 1 && (
              <Popconfirm
                title="Are you sure you wish to move to archive list?"
                placement="left"
                cancelText="No"
                getPopupContainer={(target) =>
                  document.getElementById("table-view").parentNode
                }
                okText="Yes"
                onConfirm={() => {
                  deleteUser(text?.seller_id);
                }}
              >
                <span style={{ cursor: "pointer" }} className=" text-danger">
                  {DeleteIcon}
                </span>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const resetFilter = () => {
    setSearchKey("");
  };

  const ClearSearch = async () => {
    setLoading(true);
    resetFilter();
    await getUser({ ...sortFilters, searchKey: "" });
  };
  const searchParams = new URLSearchParams(location.search)?.get("user-id");
  const addPage = new URLSearchParams(location.search)?.get("viewType");

  if (
    parseInt(localStorage?.getItem("user-type")) !== 1 ||
    contextValue?.data?.user_?.user_type !== 1
  ) {
    return <Redirect to={"dashboard"} />;
  }
  if (addPage) {
    return (
      <Wrapper id="kt_content_container" className="container-fluid mt-5">
        <div className="card">
          <div className="card-body">
            <h5 className="primaryHeading2 mb-4 text-md-left">
              <div className="d-flex align-items-center">
                {" "}
                <i
                  className="text-primary ki-duotone ki-arrow-left fs-1 me-3 cursor-pointer"
                  // onClick={() => setAddNew(false)}
                  onClick={() => history.push(`/manage-seller`)}
                >
                  <span className="path1" />
                  <span className="path2" />
                </i>
                Add New Seller
              </div>
            </h5>
            <div method="post" acceptCharset="utf-8">
              <input type="hidden" name="c_is_active" />
              <div id="email_class" className="form-group row">
                <label className="col-sm-2 text-end control-label col-form-label">
                  Email
                </label>
                <div className="col-sm-3 ">
                  <Input
                    className="form-control data-input"
                    placeholder="Email"
                    id="form_email_check"
                    name="email"
                    value={inviteEmail?.email}
                    onChange={(e) => {
                      setInviteEmail({ email: e.target.value });
                    }}
                  />
                  <small
                    id="email_error_message"
                    className="form-control-feedback"
                  ></small>
                </div>
              </div>
              <div className="form-group m-b-0">
                <div className="offset-sm-2 col-sm-5">
                  <div className="spinner-border d-none" />
                  <button
                    disabled={!inviteEmail?.email}
                    onClick={() => {
                      InviteUser();
                    }}
                    className="btn btn-sm btn-warning  mt-5"
                    id="check_user_email"
                  >
                    Invite User
                  </button>
                </div>
              </div>
              <div className="form-group mt-3 m-b-0">
                <div className="offset-sm-2 col-sm-12">
                  <p className="h6 fw-normal text-black">
                    An invitation will be sent to the new user.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {contextHolder}
      </Wrapper>
    );
  }
  if (searchParams) {
    return (
      <SellerProfileAdmin
        id={searchParams}
        onClose={() => history.push("/manage-seller")}
      />
    );
  }
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <>
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <div className="position-relative mr-4">
                <Input
                  type="text"
                  className="w-250px"
                  allowClear
                  name="all_search"
                  placeholder="Search by name or email..."
                  onChange={(e) => {
                    setSearchKey((prev) => {
                      if (!e.target.value && prev) {
                        ClearSearch();
                      }
                      return e.target.value;
                    });
                  }}
                  value={searchKey}
                />
                <button
                  onClick={() => {
                    setLoading(true);
                    getUser({ ...sortFilters, searchKey: searchKey });
                  }}
                  className="btn btn-sm ms-3 btn-search"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="card-toolbar">
              <a
                onClick={() => history.push(`/manage-seller?viewType=add`)}
                className="add-btn "
              >
                <svg
                  width={16}
                  height={16}
                  className="me-2"
                  fill="#ffffff"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                </svg>
                Add New Seller
              </a>
            </div>
          </div>
          <div className="card-body pt-0 " id="table-view">
            {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <Table
                dataSource={userList}
                onChange={handleChange}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                columns={columns?.map((d) => ({
                  ...d,
                  className: "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                scroll={{ x: "max-content" }}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
              />
            )}
            <Pagination
              loading={loading}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={(e) => {
                setLoading(true);
                setPageSize(e);
                setPage(1);
                getUser({
                  page: 1,
                  pageSize: e,
                  ...sortFilters,
                  searchKey: searchKey,
                });
              }}
              onPageNo={(e) => {
                setLoading(true);
                setPage(e);
                getUser({
                  page: e,
                  searchKey: searchKey,
                  ...sortFilters,
                });
              }}
            />
          </div>
        </div>
      </>
      <ArchiveUsers
        deleteReload={deleteReload}
        onCall={() => {
          setLoading(true);

          getUser({
            page: page,
            searchKey: searchKey,
            ...sortFilters,
          });
        }}
      />
    </Wrapper>
  );
};

export default ManageUser;
