import { Input, Modal, message } from "antd";
import React, { useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import { useEffect } from "react";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      setInputValue(selectedRow?.group_name);
    }
  }, [selectedRow]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const UpdateAction = async (data) => {
    const response = await MakeApiCall(
      `metric-group/${selectedRow?.id}`,
      "put",
      {
        group_name: inputValue,
      },
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setTimeout(() => {
        callAPI();
        onClose();
      }, 1500);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const AddUpdate = async (data) => {
    const response = await MakeApiCall(
      `metric-group`,
      "post",
      {
        group_name: inputValue,
      },
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setTimeout(() => {
        callAPI();
        onClose();
      }, 1500);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const handleOk = () => {
    if (!inputValue) {
      message.destroy();
      return message.warning("Please Enter Group Name");
    }
    message.destroy();
    message.loading("Loading...", 0);

    if (modalType === "Add") {
      AddUpdate(inputValue);
    } else {
      UpdateAction(inputValue);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={`${modalType} Metric Groups`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="mb-5">
        <label className="mt-3 mb-1">
          <span>Group Name</span>
        </label>
        <Input
          placeholder="Enter Metric Groups"
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </Modal>
  );
};

export default AddData;
