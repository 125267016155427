import {
  Button,
  DatePicker,
  Modal,
  Segmented,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  Watermark,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { WrapperContant } from "./style";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import { PremiumContent } from "../premium-content";
import { GlobalContext } from "../../../common-context";
import { MakeApiCall } from "../../../apis";
import CredentialsError from "../../credential-page";
import CardsView from "../business/lib/cards";
import { Wrapper } from "../business/style";
import { GraphMetric, ReportData } from "../business/lib/financial";
import {
  numberformat,
  NumberWithCommas,
  updatePrevDates,
} from "../../../config";
import Title from "antd/es/typography/Title";
import moment from "moment";

const AdvertisingDashboard = ({ menusList, pageTitle }) => {
  const [selectedTab, setSelectedTab] = useState("Month");
  const contextValue = useContext(GlobalContext);
  const [date, setDate] = useState(dayjs());
  const [metricSettingList, setMetricSettingList] = useState([]);

  const [viewConfig, setViewConfig] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [metricGraphLoading, setMetricGraphLoading] = useState(true);
  const [tabelLoading, setTabelLoading] = useState(true);
  const [convertedData, setConvertedData] = useState({
    salesData: [],
    salasTotal: {},
    costTotal: {},
    costData: [],
  });
  const [visible, setVisible] = useState(false);
  const [reportingData, setReportingData] = useState({});
  const [reportingDataLoading, setReportingDataLoading] = useState(true);
  const [filters, setFilters] = useState({
    start_date: dayjs()
      .subtract(2, "day")
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: dayjs().subtract(2, "day").format("YYYY-MM-DD"),
  });
  const [performanceTracker, setPerformanceTracker] = useState({});
  const [performanceTrackerLoading, setPerformanceTrackerLoading] =
    useState(true);
  const [selection_type, setSelection_type] = useState("month");
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [snapshotData, setSnapshotData] = useState({});
  const [snapshotLoading, setSnapshotLoading] = useState(true);

  const [cardList, setCardList] = useState([
    {
      label: "Paid Sales",
      root: "paid-page-views-ratio",
      id: 17,
      metric_slug: "paid-page-views-ratio",
      prefix: "$",
      suffix: "",
    },
    {
      label: "Advertising Cost",
      root: "organic-page-views-ratio",
      id: 18,
      metric_slug: "organic-page-views-ratio",
      prefix: "$",
      suffix: "",
    },
    {
      label: "ACOS",
      root: "sp-page-views-ratio",
      id: 20,
      metric_slug: "sp-page-views-ratio",
      prefix: "",
      suffix: "%",
    },
    {
      label: "ROAS",
      root: "sb-page-views-ratio",
      id: 21,
      metric_slug: "sb-page-views-ratio",
      prefix: "",
      suffix: "",
    },
    {
      label: "TACOS",
      root: "sd-page-views-ratio",
      id: 23,
      metric_slug: "sd-page-views-ratio",
      prefix: "",
      suffix: "%",
    },
    {
      label: "Daily Spend",
      root: "average-cr",
      id: 26,
      metric_slug: "average-cr",
      prefix: "$",
      suffix: "",
    },
    {
      label: "Impressions",
      root: "usp",
      id: 36,
      metric_slug: "usp",
      prefix: "",
      suffix: "",
    },
    {
      label: "Clicks",
      root: "click-to-sales",
      id: 37,
      metric_slug: "click-to-sales",
      prefix: "",
      suffix: "",
    },
    // {
    //   label: "Avg CTR",
    //   root: "click-to-sales",
    //   id: 37,
    //   metric_slug: "click-to-sales",
    //   prefix: "",
    //   suffix: "%",
    // },
    // {
    //   label: "Avg CPC",
    //   root: "click-to-sales",
    //   id: 37,
    //   metric_slug: "click-to-sales",
    //   prefix: "",
    //   suffix: "%",
    // },
    // {
    //   label: "Avg CPA",
    //   root: "click-to-sales",
    //   id: 37,
    //   metric_slug: "click-to-sales",
    //   prefix: "$",
    //   suffix: "",
    // },
    // {
    //   label: "Avg Sale Value",
    //   root: "click-to-sales",
    //   id: 37,
    //   metric_slug: "click-to-sales",
    //   prefix: "$",
    //   suffix: "",
    // },
  ]);
  const [selectedCard, setSelectedCard] = useState({
    label: "Paid Page Views Ratio",
    root: "paid-page-views-ratio",
    id: 17,
    metric_slug: "paid-page-views-ratio",
    prefix: "",
    suffix: "%",
  });
  const [overviewData, setOverviewData] = useState({
    "paid-page-views-ratio": {
      metric_id: 17,
      metric_name: "Paid Page Views Ratio",
      metric_slug: "paid-page-views-ratio",
      metric_unit: "%",
      metric_total: 19.85,
      metric_average: 26.64,
      max_value: 138.226,
      min_value: 0,
      variance_pect: -4.86,
      variance_value: -1.32,
      ema_trend: "DOWN",
      up_counter: 0,
      down_counter: 1,
      stagnant_counter: 0,
      movement: "FALLING",
      falling_counter: 1,
      rising_counter: 0,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 30.67,
          ema_value: 27.75,
        },
        {
          chartDate: "Oct-16",
          metric_value: 27.81,
          ema_value: 27.76,
        },
        {
          chartDate: "Oct-17",
          metric_value: 30.12,
          ema_value: 28.19,
        },
        {
          chartDate: "Oct-18",
          metric_value: 29.85,
          ema_value: 28.49,
        },
        {
          chartDate: "Oct-19",
          metric_value: 22.04,
          ema_value: 27.32,
        },
        {
          chartDate: "Oct-20",
          metric_value: 26.12,
          ema_value: 27.1,
        },
        {
          chartDate: "Oct-21",
          metric_value: 19.85,
          ema_value: 25.78,
        },
      ],
    },
    "organic-page-views-ratio": {
      metric_id: 18,
      metric_name: "Organic Page Views Ratio",
      metric_slug: "organic-page-views-ratio",
      metric_unit: "%",
      metric_total: 80.15,
      metric_average: 73.36,
      max_value: 100,
      min_value: -38.23,
      variance_pect: 1.81,
      variance_value: 1.32,
      ema_trend: "STAGNANT",
      up_counter: 0,
      down_counter: 0,
      stagnant_counter: 13,
      movement: "RISING",
      falling_counter: 0,
      rising_counter: 1,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 69.33,
          ema_value: 72.25,
        },
        {
          chartDate: "Oct-16",
          metric_value: 72.19,
          ema_value: 72.24,
        },
        {
          chartDate: "Oct-17",
          metric_value: 69.88,
          ema_value: 71.81,
        },
        {
          chartDate: "Oct-18",
          metric_value: 70.15,
          ema_value: 71.51,
        },
        {
          chartDate: "Oct-19",
          metric_value: 77.96,
          ema_value: 72.68,
        },
        {
          chartDate: "Oct-20",
          metric_value: 73.88,
          ema_value: 72.9,
        },
        {
          chartDate: "Oct-21",
          metric_value: 80.15,
          ema_value: 74.22,
        },
      ],
    },
    "sp-page-views-ratio": {
      metric_id: 20,
      metric_name: "SP Page Views Ratio",
      metric_slug: "sp-page-views-ratio",
      metric_unit: "%",
      metric_total: 14.19,
      metric_average: 20.71,
      max_value: 98.17,
      min_value: 0,
      variance_pect: -6.07,
      variance_value: -1.29,
      ema_trend: "DOWN",
      up_counter: 0,
      down_counter: 3,
      stagnant_counter: 0,
      movement: "FALLING",
      falling_counter: 1,
      rising_counter: 0,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 27.02,
          ema_value: 23.66,
        },
        {
          chartDate: "Oct-16",
          metric_value: 24.19,
          ema_value: 23.76,
        },
        {
          chartDate: "Oct-17",
          metric_value: 24.8,
          ema_value: 23.95,
        },
        {
          chartDate: "Oct-18",
          metric_value: 20.81,
          ema_value: 23.38,
        },
        {
          chartDate: "Oct-19",
          metric_value: 15.72,
          ema_value: 21.98,
        },
        {
          chartDate: "Oct-20",
          metric_value: 18.24,
          ema_value: 21.3,
        },
        {
          chartDate: "Oct-21",
          metric_value: 14.19,
          ema_value: 20.01,
        },
      ],
    },
    "sb-page-views-ratio": {
      metric_id: 21,
      metric_name: "SB Page Views Ratio",
      metric_slug: "sb-page-views-ratio",
      metric_unit: "%",
      metric_total: 0,
      metric_average: 3.12,
      max_value: 17.43,
      min_value: 0,
      variance_pect: -18.18,
      variance_value: -0.6,
      ema_trend: "DOWN",
      up_counter: 0,
      down_counter: 1,
      stagnant_counter: 0,
      movement: "FALLING",
      falling_counter: 1,
      rising_counter: 0,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 2.95,
          ema_value: 2.02,
        },
        {
          chartDate: "Oct-16",
          metric_value: 2.47,
          ema_value: 2.11,
        },
        {
          chartDate: "Oct-17",
          metric_value: 3.37,
          ema_value: 2.34,
        },
        {
          chartDate: "Oct-18",
          metric_value: 3.8,
          ema_value: 2.6,
        },
        {
          chartDate: "Oct-19",
          metric_value: 4.21,
          ema_value: 2.89,
        },
        {
          chartDate: "Oct-20",
          metric_value: 5.06,
          ema_value: 3.29,
        },
        {
          chartDate: "Oct-21",
          metric_value: 0,
          ema_value: 2.69,
        },
      ],
    },
    "sd-page-views-ratio": {
      metric_id: 23,
      metric_name: "SD Page Views Ratio",
      metric_slug: "sd-page-views-ratio",
      metric_unit: "%",
      metric_total: 5.66,
      metric_average: 2.8,
      max_value: 22.63,
      min_value: 0,
      variance_pect: 22.9,
      variance_value: 0.57,
      ema_trend: "UP",
      up_counter: 1,
      down_counter: 0,
      stagnant_counter: 0,
      movement: "RISING",
      falling_counter: 0,
      rising_counter: 2,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 0.7,
          ema_value: 2.05,
        },
        {
          chartDate: "Oct-16",
          metric_value: 1.16,
          ema_value: 1.89,
        },
        {
          chartDate: "Oct-17",
          metric_value: 1.95,
          ema_value: 1.9,
        },
        {
          chartDate: "Oct-18",
          metric_value: 5.23,
          ema_value: 2.51,
        },
        {
          chartDate: "Oct-19",
          metric_value: 2.11,
          ema_value: 2.44,
        },
        {
          chartDate: "Oct-20",
          metric_value: 2.82,
          ema_value: 2.51,
        },
        {
          chartDate: "Oct-21",
          metric_value: 5.66,
          ema_value: 3.08,
        },
      ],
    },
    "average-cr": {
      metric_id: 26,
      metric_name: "Average CR",
      metric_slug: "average-cr",
      metric_unit: "%",
      metric_total: 0,
      metric_average: 0.35,
      max_value: 1.47,
      min_value: 0,
      variance_pect: -18.19,
      variance_value: -0.07,
      ema_trend: "DOWN",
      up_counter: 0,
      down_counter: 3,
      stagnant_counter: 0,
      movement: "FALLING",
      falling_counter: 4,
      rising_counter: 0,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 0.52,
          ema_value: 0.42,
        },
        {
          chartDate: "Oct-16",
          metric_value: 0.31,
          ema_value: 0.4,
        },
        {
          chartDate: "Oct-17",
          metric_value: 0.8,
          ema_value: 0.47,
        },
        {
          chartDate: "Oct-18",
          metric_value: 0.48,
          ema_value: 0.47,
        },
        {
          chartDate: "Oct-19",
          metric_value: 0.26,
          ema_value: 0.43,
        },
        {
          chartDate: "Oct-20",
          metric_value: 0.08,
          ema_value: 0.37,
        },
        {
          chartDate: "Oct-21",
          metric_value: 0,
          ema_value: 0.3,
        },
      ],
    },
    usp: {
      metric_id: 36,
      metric_name: "USP",
      metric_slug: "usp",
      metric_unit: "%",
      metric_total: 0.77,
      metric_average: 1.79,
      max_value: 18.69,
      min_value: 0,
      variance_pect: -9.94,
      variance_value: -0.17,
      ema_trend: "DOWN",
      up_counter: 0,
      down_counter: 1,
      stagnant_counter: 0,
      movement: "FALLING",
      falling_counter: 1,
      rising_counter: 0,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 0.12,
          ema_value: 0.97,
        },
        {
          chartDate: "Oct-16",
          metric_value: 0.66,
          ema_value: 0.92,
        },
        {
          chartDate: "Oct-17",
          metric_value: 7.48,
          ema_value: 2.11,
        },
        {
          chartDate: "Oct-18",
          metric_value: 1.33,
          ema_value: 1.97,
        },
        {
          chartDate: "Oct-19",
          metric_value: 0.6,
          ema_value: 1.72,
        },
        {
          chartDate: "Oct-20",
          metric_value: 1.6,
          ema_value: 1.7,
        },
        {
          chartDate: "Oct-21",
          metric_value: 0.77,
          ema_value: 1.53,
        },
      ],
    },
    "click-to-sales": {
      metric_id: 37,
      metric_name: "Click to sales",
      metric_slug: "click-to-sales",
      metric_unit: "%",
      metric_total: 0.42,
      metric_average: 0.47,
      max_value: 2.35,
      min_value: 0,
      variance_pect: -2.92,
      variance_value: -0.01,
      ema_trend: "STAGNANT",
      up_counter: 0,
      down_counter: 0,
      stagnant_counter: 1,
      movement: "RISING",
      falling_counter: 0,
      rising_counter: 1,
      flat_counter: 0,
      metric_chart_data: [
        {
          chartDate: "Oct-15",
          metric_value: 0.17,
          ema_value: 0.52,
        },
        {
          chartDate: "Oct-16",
          metric_value: 0.39,
          ema_value: 0.49,
        },
        {
          chartDate: "Oct-17",
          metric_value: 1.24,
          ema_value: 0.63,
        },
        {
          chartDate: "Oct-18",
          metric_value: 0.59,
          ema_value: 0.62,
        },
        {
          chartDate: "Oct-19",
          metric_value: 0.26,
          ema_value: 0.56,
        },
        {
          chartDate: "Oct-20",
          metric_value: 0.25,
          ema_value: 0.5,
        },
        {
          chartDate: "Oct-21",
          metric_value: 0.42,
          ema_value: 0.49,
        },
      ],
    },
  });
  const borderColor = {
    1: "#E31715",
    2: "#FDC71E",
    3: "#00B55D",
  };
  const backgroundColor = {
    1: "#FFF6F6",
    2: "#FFFEF6",
    3: "#F6FFFA",
  };
  const [dateFilter, setDateFilter] = useState(
    dayjs(getWeekDates(dayjs().format("YYYY-MM-DD"))?.start, "YYYY-MM-DD")
      ?.subtract(1, "day")
      ?.format("YYYY-MM-DD")
  );
  const SellerStatus = [
    {
      title: "Paid Sales",

      key: "paid_sales",
      subTitle: "Units Sold",

      subKey: "units_sold",
      suffix: "",
      subSuffix: "",
      prefix: "$",
      subPrefix: "",
    },
    {
      title: "Advertising Cost",
      key: "advertising_cost",

      suffix: "",
      prefix: "$",
    },
    {
      title: "ACOS",
      key: "acos",

      suffix: "%",
      prefix: "",
    },
    {
      title: "ROAS",
      key: "roas",

      suffix: "",
      prefix: "",
    },

    {
      title: "TACOS",
      key: "tacos",

      suffix: "%",
      prefix: "",
    },
    {
      title: "Avg CPA",
      key: "avg_cpa",

      suffix: "",
      prefix: "$",
    },
    // {
    //   title: "Avg CPAS",
    //   key: "avg_cpas",

    //   suffix: "",
    //   prefix: "$",
    // },
    {
      title: "Impressions",

      key: "impressions",
      subTitle: "Share",
      subKey: "impression_share",

      suffix: "",
      subSuffix: "%",
      prefix: "",
      subPrefix: "",
    },
    {
      title: "Clicks",
      key: "clicks",

      suffix: "",
      prefix: "",
    },
    {
      title: "Avg CTR",
      key: "avg_ctr",

      suffix: "%",
      prefix: "",
    },
    {
      title: "Avg CPC",
      key: "avg_cpc",

      suffix: "%",
      prefix: "",
    },
    {
      title: "Daily Spends",
      key: "daily_spends",

      suffix: "",
      prefix: "$",
    },
    {
      title: "Avg Sale Value",
      key: "avg_sale_value",

      suffix: "",
      prefix: "$",
    },
    // {
    //   title: "Avg Ad Sale Value",
    //   key: "avg_ad_sale_value",
    //   suffix: "",
    //   prefix: "$",
    // },
  ];
  const getBorderColor = (value) => {
    if (value == 0 || value === "STAGNANT") {
      return 2;
    }
    if (value === "DOWN") {
      return 1;
    }
    if (value === "UP") {
      return 3;
    }
    return 3;
  };
  const graphDataFiltered =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.metric_value
    ) || [];
  const graphDataFilteredEma_value =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.ema_value
    ) || [];
  function formatNumberWithCommas(number) {
    return number?.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
  const numberformat_ = (num) => {
    // Format number with no decimals
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const minGraph = Math.min(
    ...([...graphDataFiltered, ...graphDataFilteredEma_value] || [])
  );
  const maxGraph = Math.max(
    ...([...graphDataFiltered, ...graphDataFilteredEma_value] || [])
  );
  const revenu_status = {
    chart: {
      height: 355,

      type: "area",
      toolbar: { show: !1 },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    colors: [
      borderColor?.[
        getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
      ],
      "#000",
    ],
    markers: {
      size: 4,
      colors: "#FFF",
      strokeColors:
        borderColor?.[
          getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
        ],
      hover: {
        size: 5,
      },
    },
    xaxis: {
      tickAmount: 7,
      offsetX: 10,
      labels: {
        rotateAlways: -45,
        style: {
          fontWeight: "bold",
        },
      },
    },
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [3, 3],
      dashArray: [0, 4],
      lineCap: "round",
    },

    labels:
      overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
        (d) => d?.date || d?.weekNum || d?.weekDate || d?.chartDate
      ) || [],
    yaxis: [
      {
        seriesName: "",
        tickAmount: 7,
        axisTicks: {
          show: true,
        },
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
      {
        seriesName: "EMA",
        tickAmount: 7,
        opposite: true,
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        axisTicks: {
          show: true,
        },

        title: {
          text: "Path",
          style: {
            fontWeight: 700,
            color: "#065ad8",
            fontSize: "1.15rem",
          },
        },
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      yaxis: { lines: { show: !1 } },
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
    },

    legend: { show: !0 },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const label = w?.globals?.categoryLabels?.[value - 1] || "";
          return `<strong>${label}</strong>`;
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const prefix = selectedCard?.prefix || "";
          const suffix = selectedCard?.suffix || "";

          return signConvert(
            prefix +
              numberformat(parseFloat(parseFloat(value || 0).toFixed(2))) +
              suffix
          );
        },
      },
    },
  };
  const getUserReportingPeriod = async () => {
    setReportingDataLoading(true);
    const response = await MakeApiCall(
      `user/reporting-period`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setReportingData(response?.data);

      setReportingDataLoading(false);
    } else {
      setReportingDataLoading(false);
      message.warning(response?.message);
    }
  };

  function getLastFiveWeeks() {
    const weeks = [];
    const currentDate = new Date();

    for (let i = 0; i < 5; i++) {
      // Get the start of the week (Sunday)
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() - i * 7);

      // Get the end of the week (Saturday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      // Format the start and end dates if necessary
      weeks.push({
        start: startOfWeek.toISOString().split("T")[0], // YYYY-MM-DD format
        end: endOfWeek.toISOString().split("T")[0],
      });
    }

    return weeks;
  }

  const columns = {
    week: [
      {
        title: "",
        width: 200,
        dataIndex: "key",
        key: "key",
      },
      ...(performanceTracker?.columnsList || [])?.map((d, i) => ({
        title: d,
        align: "center",
        dataIndex: `timeline_${i + 1}`,
        key: `timeline_${i + 1}`,
      })),
    ],
    month: [
      {
        title: "",
        dataIndex: "key",
        width: 200,
        key: "key",
      },
      ...(performanceTracker?.columnsList || [])?.map((d, i) => ({
        title: d,
        align: "center",
        dataIndex: `timeline_${i + 1}`,
        key: `timeline_${i + 1}`,
      })),
    ],
  };

  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2927 5.49996C12.7119 5.49996 12.945 5.98493 12.6831 6.31231L8.39011 11.6786C8.18995 11.9288 7.8094 11.9288 7.60924 11.6786L3.31622 6.31231C3.05431 5.98493 3.2874 5.49996 3.70665 5.49996L12.2927 5.49996Z"
        fill="#E31715"
      />
    </svg>
  );

  const successIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70633 11.5C3.28708 11.5 3.05399 11.015 3.31589 10.6877L7.60891 5.32138C7.80908 5.07117 8.18962 5.07117 8.38978 5.32138L12.6828 10.6877C12.9447 11.015 12.7116 11.5 12.2924 11.5L3.70633 11.5Z"
        fill="#00B55D"
      />
    </svg>
  );

  const getValueShow = (value) => {
    try {
      if (value == 0) {
        return <b style={{ color: "#000" }}>{value}%</b>;
      } else if (parseFloat(value?.split(",")?.join("")) < 0) {
        return (
          <b style={{ color: "red" }}>
            {errorIcon}
            {value}%
          </b>
        );
      }
      return (
        <b style={{ color: "green" }}>
          {successIcon}
          {value}%
        </b>
      );
    } catch (error) {}
  };
  const getUpdatedPCA = async () => {
    const response = await MakeApiCall(
      `funnel/premium-content-status`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user_ = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user_));
      contextValue.updateCommonGlobalVal({
        user_: user_,
        credentialsStatus: obj,
      });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getUpdatedPCA();
    }, 10000);
    getUserReportingPeriod();
    return () => {
      clearInterval(interval);
    };
  }, []);
  const PCAUpdate = async (data, message_) => {
    const response = await MakeApiCall(
      `seller/pca-stage/${contextValue?.data?.user_?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user));
      contextValue.updateCommonGlobalVal({
        user_: user,
        credentialsStatus: obj,
      });
      if (message_) {
        Modal.success({
          content: message_,
          centered: true,
        });
      }
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const AdsPerformanceTracker = async (type) => {
    setPerformanceTrackerLoading(true);
    setPerformanceTracker({});
    const response = await MakeApiCall(
      `ads/performance-tracker?selection_type=${type}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      setPerformanceTrackerLoading(false);
      const metrics = ["ACOS", "ROAS", "TACOS"];
      const data = metrics.map((metric) => {
        const row = { key: metric };
        response?.data?.reverse()?.forEach((week, index) => {
          row[`timeline_${index + 1}`] = `${
            week[metric]
              ? NumberWithCommas(
                  parseFloat(parseFloat(week[metric] || 0).toFixed(0))
                )
              : 0
          }${metric !== "ROAS" ? "%" : ""}`;
        });

        return row;
      });

      setPerformanceTracker({
        tableList: data || [],
        columnsList:
          response?.data
            ?.map((d) => {
              console.log(d, "dddddd");
              return (
                d?.month_name ||
                `${d?.week_number} ${dayjs(
                  d?.week_start_date,
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY")} To ${dayjs(
                  d?.week_end_date,
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY")}`
              );
            })
            ?.reverse() || [],
      });
    } else {
      message.destroy();
      setPerformanceTrackerLoading(false);
      setPerformanceTracker([]);
      message.warning(response?.message);
    }
  };

  function convertData(one) {
    const outputObject = {};

    const two = Object?.keys(Object.values(one)?.[0])?.map((d) => {
      Object?.keys(one).forEach((key) => {
        const num = Math.round(one?.[key]?.[d] || 0);

        outputObject[key] = num?.toLocaleString("en-US", {
          minimumFractionDigits: 0,
        });
      });

      return {
        type: d,
        ...outputObject,
      };
    });

    return two;
  }

  const get_ads_summary = {
    salesData: [
      "sponsored_products",
      "sponsored_brands",
      "sponsored_brands_video",
      "sponsored_display",
      "sponsored_television",
    ],
    salasTotal: ["total"],
    costTotal: ["total"],
    costData: [
      "sponsored_products",
      "sponsored_brands",
      "sponsored_brands_video",
      "sponsored_display",
      "sponsored_television",
    ],
  };

  const getList = async (data) => {
    setTabelLoading(true);
    setConvertedData({
      salesData: [],
      costData: [],
      salasTotal: {},
      costTotal: {},
    });

    try {
      const response = await MakeApiCall(
        `ads/get-summary?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setConvertedData({
          salesData: convertData(response?.data?.salesData)?.filter((item) =>
            get_ads_summary?.salesData.includes(item.type)
          ),
          costData: convertData(response?.data?.costData)?.filter((item) =>
            get_ads_summary?.costData.includes(item.type)
          ),
          costTotal: convertData(response?.data?.costData)?.filter((item) =>
            ["total"].includes(item.type)
          )?.[0],
          salasTotal: convertData(response?.data?.salesData)?.filter((item) =>
            ["total"].includes(item.type)
          )?.[0],
        });
        setTabelLoading(false);
      } else {
        setTabelLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setTabelLoading(false);
      setConvertedData({
        salesData: [],
        costData: [],
        salasTotal: {},
        costTotal: {},
      });
      message.destroy();
      message.error(error?.message);
    }
  };

  const AdsPerformanceSnapshot = async (data) => {
    setSnapshotLoading(true);
    setSnapshotData({});
    try {
      const response = await MakeApiCall(
        `ads/performance-snapshot?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setSnapshotLoading(false);
        setSnapshotData(response?.data || {});
      } else {
        setSnapshotLoading(false);
        setSnapshotData({});
        message.warning(response?.message);
      }
    } catch (error) {
      setSnapshotLoading(false);
      setSnapshotData({});
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    setTabelLoading(true);
    getList();
    AdsPerformanceSnapshot();

    AdsPerformanceTracker(selection_type);
    return () => {};
  }, []);

  const findYear = dayjs(filters?.start_date, "YYYY-MM-DD").format("YYYY");
  const selectedPeriod = `${dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM DD 'YY"
  )} - ${dayjs(filters?.end_date, "YYYY-MM-DD").format("MMM DD 'YY")}`;

  const columnsFilterTop = {
    This_Month: {
      [`This Month`]: "this_month",
      [`%Share`]: "this_month_ratio",
      [`Proj. Month`]: "projected_month",
      ["Last Month"]: "last_month",
      ["%Share  "]: "last_month_ratio",
      ["Variance"]: "variance",
      [`  `]: "variance_pect",
      [`This Month Last Year`]: "this_month_last_year",
      ["%Share "]: "tmly_ratio",
      [`TMLY Variance`]: "tmly_variance",
      [` `]: "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`%Share`]: "selected_range_ratio",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`%Share `]: "previous_period_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [`Last Month`]: "last_month",
      [`%Share`]: "last_month_ratio",
      [`Previous Month`]: "previous_month",
      [`%Share `]: "previous_month_ratio",
      [`Variance`]: "variance",
      [` `]: "variance_pect",
      [`Last Month Last Year`]: "last_month_last_year",
      [`%Share  `]: "lmly_ratio",
      [`LMLY Variance`]: "lmly_variance",
      [`  `]: "lmly_variance_pect",
    },
    Yesterday: {
      [`Yesterday`]: "yesterday",
      [`%Share`]: "yesterday_ratio",
      "Day Before Yesterday ": "day_before_yesterday",

      [`%Share `]: "day_before_yesterday_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",
      [`%Share  `]: "yly_ratio",
      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      [`This Week Actual`]: "this_week",
      [`%Share`]: "this_week_ratio",
      "This Week Proj. ": "projected_week",

      "Last Week": "last_week",
      [`%Share `]: "last_week_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      [`%Share  `]: "lytw_ratio",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      [`This Quarter Actual`]: "this_quarter",
      [`%Share`]: "this_quarter_ratio",
      "This Quarter Proj.": "projected_quarter",

      "Last Quarter": "last_quarter",
      [`%Share `]: "last_quarter_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      [`%Share  `]: "tyql_ratio",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`%Share `]: "last_year_q1_ratio",
      [`${findYear} Q2`]: "last_year_q2",
      [`%Share  `]: "last_year_q2_ratio",
      [`${findYear} Q3`]: "last_year_q3",
      [`%Share   `]: "last_year_q3_ratio",
      [`${findYear} Q4`]: "last_year_q4",
      [`%Share`]: "last_year_q4_ratio",

      [`${findYear} Total`]: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`%Share`]: "this_year_q1_ratio",
      [`${findYear} Q2`]: "this_year_q2",
      [`%Share `]: "this_year_q2_ratio",
      [`${findYear} Q3`]: "this_year_q3",
      [`%Share  `]: "this_year_q3_ratio",
      [`${findYear} Q4`]: "this_year_q4",
      [`%Share   `]: "this_year_q4_ratio",
      [`${findYear} Total `]: "this_year_total",
      // [`${previousYear} Total`]: "last_year_total",
      [`This Year Proj. `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };
  const columnsFilterAdsCost = {
    This_Month: {
      [`This Month`]: "this_month",
      [`ACOS`]: "this_month_acos",
      [`Proj. Month`]: "projected_month",
      ["Last Month"]: "last_month",
      [`ACOS `]: "last_month_acos",
      ["Variance"]: "variance",
      [`  `]: "variance_pect",
      [`This Month Last Year`]: "this_month_last_year",
      [`ACOS  `]: "tmly_acos",
      [`TMLY Variance`]: "tmly_variance",
      [` `]: "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`ACOS`]: "selected_range_acos",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`ACOS `]: "previous_period_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [`Last Month`]: "last_month",
      [`ACOS`]: "last_month_acos",
      [`Previous Month`]: "previous_month",
      [`ACOS `]: "previous_month_acos",
      [`Variance`]: "variance",
      [` `]: "variance_pect",
      [`Last Month Last Year`]: "last_month_last_year",
      [`ACOS  `]: "lmly_acos",
      [`LMLY Variance`]: "lmly_variance",
      [`  `]: "lmly_variance_pect",
    },
    Yesterday: {
      [`Yesterday`]: "yesterday",
      [`ACOS`]: "yesterday_acos",
      "Day Before Yesterday ": "day_before_yesterday",
      [`ACOS `]: "day_before_yesterday_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",
      [`ACOS  `]: "yly_acos",
      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      [`This Week Actual`]: "this_week",
      [`ACOS`]: "this_week_acos",
      "This Week Proj. ": "projected_week",
      // [`ACOS `]: "projected_week_ratio",
      "Last Week": "last_week",
      [`ACOS  `]: "last_week_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      [`ACOS   `]: "lytw_acos",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      [`This Quarter Actual`]: "this_quarter",
      [`ACOS`]: "this_quarter_acos",
      "This Quarter Proj.": "projected_quarter",
      "Last Quarter": "last_quarter",
      [`ACOS  `]: "last_quarter_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      [`ACOS `]: "tqly_acos",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`ACOS`]: "last_year_q1_acos",
      [`${findYear} Q2`]: "last_year_q2",
      [`ACOS `]: "last_year_q2_acos",
      [`${findYear} Q3`]: "last_year_q3",
      [`ACOS  `]: "last_year_q3_acos",
      [`${findYear} Q4`]: "last_year_q4",
      [`ACOS  `]: "last_year_q4_acos",

      [`${findYear} Total`]: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`ACOS`]: "this_year_q1_acos",
      [`${findYear} Q2`]: "this_year_q2",
      [`ACOS `]: "this_year_q2_acos",
      [`${findYear} Q3`]: "this_year_q3",
      [`ACOS  `]: "this_year_q3_acos",
      [`${findYear} Q4`]: "this_year_q4",
      [`ACOS   `]: "this_year_q4_acos",
      [`${findYear} Total `]: "this_year_total",
      [`This Year Proj. `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };

  const items = [
    {
      key: "1",
      label: "Sponsored Products",
      children: null,
    },
    {
      key: "2",
      label: "Sponsored Brands",
      children: null,
    },
    {
      key: "3",
      label: "Sponsored Brand Video",
      children: null,
    },
    {
      key: "4",
      label: "Sponsored Display",
      children: null,
    },
  ];

  const acosData = {
    spProductsACOS: [10, 12, 11, 13, 14, 13, 15, 14, 13, 12, 11, 10],
    spBrandsACOS: [9, 11, 10, 12, 13, 12, 11, 10, 9, 10, 11, 12],
    spDisplayACOS: [15, 14, 15, 16, 17, 16, 15, 14, 13, 14, 15, 16],
    spTelevisionACOS: [20, 19, 18, 17, 18, 19, 20, 19, 18, 17, 16, 15],
    tacos: [8, 7, 9, 8, 9, 8, 7, 6, 7, 8, 7, 6],
  };

  const salesData = {
    spProductsSales: [
      12000, 14000, 15000, 13000, 18000, 16500, 14000, 17000, 19000, 20000,
      21000, 22000,
    ],
    spBrandsSales: [
      8000, 9000, 7000, 8500, 8800, 9200, 8000, 9300, 10000, 11000, 12000, 9500,
    ],
    spDisplaySales: [
      4000, 4500, 4800, 4600, 4700, 4900, 5200, 5400, 5500, 5300, 5600, 5900,
    ],
    spTelevisionSales: [
      2000, 2200, 2400, 2300, 2100, 2500, 2700, 2600, 2800, 2900, 3000, 3100,
    ],
    spProductsAdCosts: [
      5000, 5300, 5500, 5200, 5400, 5600, 5700, 5800, 5900, 6000, 6100, 6200,
    ],
    spBrandsAdCosts: [
      3000, 3200, 3400, 3100, 3300, 3500, 3600, 3700, 3800, 3900, 4000, 4100,
    ],
    spDisplayAdCosts: [
      2000, 2100, 2200, 2000, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
    ],
    spTelevisionAdCosts: [
      1000, 1100, 1200, 1150, 1300, 1400, 1450, 1500, 1550, 1600, 1650, 1700,
    ],
  };
  const options = {
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis for columns
        labels: {
          format: "${value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Sales",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              "gray",
          },
        },
      },
      {
        // Secondary yAxis for lines
        title: {
          text: "ACOS",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} %",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      // layout: "horizontal",
      // align: "center",
      // x: 120,
      // verticalAlign: "bottom",
      // y: 100,
      // floating: false,
      // backgroundColor:
      //   Highcharts.defaultOptions.legend.backgroundColor || // theme
      //   "rgba(255,255,255,0.25)",
    },
    series: [
      {
        name: "Sp Products Sales",
        type: "column",
        data: salesData.spProductsSales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Brands Sales",
        type: "column",
        data: salesData.spBrandsSales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Display Sales",
        type: "column",
        data: salesData.spDisplaySales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Television Sales",
        type: "column",
        data: salesData.spTelevisionSales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Products Ad Costs",
        type: "column",
        data: salesData.spProductsAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Brands Ad Costs",
        type: "column",
        data: salesData.spBrandsAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Display Ad Costs",
        type: "column",
        data: salesData.spDisplayAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Television Ad Costs",
        type: "column",
        data: salesData.spTelevisionAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },

      {
        name: "SP Products ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spProductsACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "SP Brands ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spBrandsACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "SP Display ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spDisplayACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "SP Television ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spTelevisionACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "TACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.tacos,
        tooltip: {
          valueSuffix: " %",
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    // ... (other options)
  };
  const a = "100";
  const l = "#F1F1F2";
  const green = "#50cd89";
  const yellow = "#ffc803";
  const red = "#f1416c";
  const optionsComman = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2 },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      enabled: false,
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },

    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: {
      size: 2,
      colors: "#FFF",

      hover: {
        size: 3,
      },
    },
  };
  const showMetrix = () => {
    if (metricSettingList?.length === 0) {
      message.destroy();
      return message.warning("Config List is not available");
    }
    setViewConfig(true);
  };
  const updateChartOptions = (color) => {
    return {
      ...optionsComman,
      stroke: { ...optionsComman.stroke, colors: [color] },
      colors: [color],
      markers: {
        ...optionsComman.markers,
        strokeColors: color,
      },
      xaxis: {
        ...optionsComman?.xaxis,
        crosshairs: {
          ...optionsComman?.xaxis.crosshairs,
          stroke: {
            ...optionsComman?.xaxis.crosshairs.stroke,
            colors: [color],
          },
        },
      },
    };
  };

  const getOp = (value) => {
    switch (value) {
      case 0:
      case "STAGNANT":
        return updateChartOptions(yellow);
      case "DOWN":
        return updateChartOptions(red);
      case "UP":
        return updateChartOptions(green);
      default:
        return updateChartOptions(green); // or any default color
    }
  };
  const getFooter = (value) => {
    if (value == 0 || value === "Flat") {
      return 2;
    }
    if (value === "FLAT") {
      return 2;
    }
    if (value === "Falling") {
      return 1;
    }
    if (value === "FALLING") {
      return 1;
    }
    if (value === "Rising") {
      return 3;
    }
    if (value === "RISING") {
      return 3;
    }
    return 3;
  };
  const getArrowColor = (value) => {
    if (!value || value == 0 || value === "Flat") {
      return "rotateX(92deg)";
    }
    if (value === "FLAT") {
      return "rotateX(92deg)";
    }
    if (value === "Falling") {
      return "rotateX(180deg)";
    }
    if (value === "FALLING") {
      return "rotateX(180deg)";
    }
    if (value === "Rising") {
      return "rotateX(0deg)";
    }
    if (value === "RISING") {
      return "rotateX(0deg)";
    }
    return "rotateX(0deg)";
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs()],
    },
    {
      label: "Last Month",
      value: [dayjs().subtract(1, "month").startOf("month"), dayjs()],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs()],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];

  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });

  const handleDateRangeChange = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setSelectedRange(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    // setSelectedRangeLine(
    //   selectedPresetRange?.length !== 0
    //     ? selectedPresetRange?.[0]?.label
    //     : "custom"
    // );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };
  const ratioView = (key) => {
    return (
      key === "_" ||
      key === "__" ||
      key === "___" ||
      key === "____" ||
      key === "Avg CR"
    );
  };
  const signConvert = (value) => {
    return value.replace("$-", "-$");
  };
  const restart = true;
  const signFind = {
    salesData: "$",
    grossProfitData: "$",
    gpMarginData: "%",
    expensesData: "$",
    totalProfitData: "$",
    netMarginData: "%",
    refundData: "$",
  };
  function getWeekDates(date) {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - dayOfWeek);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return {
      start: startDate.toISOString().split("T")[0],
      end: endDate.toISOString().split("T")[0],
    };
  }

  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "ads-360")?.is_restricted === 1
  ) {
    return <CredentialsError type="ads-360" />;
  }
  return (
    <WrapperContant
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="row">
        <div className="header-title-page">
          <div className="d-flex align-items-center title-text">
            <h2 className="mb-0">{pageTitle}</h2>{" "}
          </div>
        </div>
      </div>
      <DatePicker
        className="w-150px custom-date"
        onChange={(e) => setDate(e)}
        value={date}
        picker="week"
        format={"YYYY-MMM-DD"}
      />
      <div id="kt_app_toolbar" className="app-toolbar  ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div
            style={{
              justifyContent: viewConfig ? "flex-start" : "space-between",
              width: "100%",
            }}
            className="mb-6 d-flex top-bar-page-header mt-4"
          >
            <div className="page-header-info">
              <div className="discription-text">
                Ads 360 and Trends for the week of{" "}
                {dayjs(getWeekDates(dateFilter)?.start, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                )}{" "}
                to{" "}
                {dayjs(getWeekDates(dateFilter)?.end, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                )}
              </div>
            </div>

            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 20,
                display: "flex",
              }}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 13,
                    height: 13,
                    background: "#00B55D",
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{
                    color: "#00B55D",
                    fontSize: 14,

                    fontWeight: "700",

                    wordWrap: "break-word",
                  }}
                >
                  Growth
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 13,
                    height: 13,
                    background: "#FDC71E",
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{
                    color: "#FDC71E",
                    fontSize: 14,
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}
                >
                  Stagnant
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 13,
                    height: 13,
                    background: "#E31715",
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{
                    color: "#E31715",
                    fontSize: 14,
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}
                >
                  Decline
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Wrapper>
        <div
          className="row g-5 g-xl-5 Financial-cus-row"
          style={{
            zoom: "90%",
          }}
        >
          <div className=" col-xl-8 mb-5 mb-xl-5 mh-450px overflow-auto">
            <CardsView
              cardList={cardList}
              type="D"
              setSelectedCard={setSelectedCard}
              backgroundColor={backgroundColor}
              getBorderColor={getBorderColor}
              overviewData={overviewData}
              borderColor={borderColor}
              signConvert={signConvert}
              getOp={getOp}
              getFooter={getFooter}
              getArrowColor={getArrowColor}
              selectedCard={selectedCard}
              overviewLoading={false}
              setCardList={setCardList}
              setMetricGraphLoading={() => {}}
            />
          </div>
          {GraphMetric(
            selectedCard,
            borderColor,
            getBorderColor,
            overviewData,
            signFind,
            restart,
            revenu_status,
            getArrowColor,
            getFooter,
            showMetrix,
            overviewLoading,
            metricGraphLoading
          )}
        </div>
      </Wrapper>

      <div id="kt_app_toolbar" className="app-toolbar mb-2 mt-5 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-row justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading me-3 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Performance Tracker
            </h1>
            <Segmented
              options={[
                {
                  label: "Week",
                  value: "week",
                },
                {
                  label: "Month",
                  value: "month",
                },
              ]}
              value={selection_type}
              onChange={(value) => {
                AdsPerformanceTracker(value);
                setSelection_type(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Table
            columns={columns?.[selection_type]}
            dataSource={performanceTracker?.tableList || []}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            loading={performanceTrackerLoading}
            bordered
            size="small"
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      <div id="kt_app_toolbar" className="app-toolbar mb-0 mt-5 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-row justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading me-3 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Performance Snapshot
            </h1>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <Tag color="blue">
              <svg
                width={16}
                height={16}
                fill="#065ad8"
                className="me-2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM11 6h2.4v2.4H11V6Zm0 4.8h2.4V18H11v-7.2Z"
                  clipRule="evenodd"
                />
              </svg>
              Reported data determined by SIP Registration and Amazon
              constraints.{" "}
              <a
                style={{ textDecoration: "underline", color: "#065ad8" }}
                onClick={(e) => setVisible(true)}
              >
                See your reporting period
              </a>
            </Tag>
            <Tag color="blue-inverse" className="me-2">
              {selectedRange === "custom" ? "Selected period" : selectedRange}
            </Tag>
            <DatePicker.RangePicker
              presets={presetRanges}
              size="middle"
              style={{
                width: "280px",
                border: "1px solid #D7D7D7",
              }}
              format={"YYYY-MMM-DD"}
              value={
                filters?.start_date && filters?.end_date
                  ? [
                      dayjs(filters?.start_date, "YYYY-MM-DD"),
                      dayjs(filters?.end_date, "YYYY-MM-DD"),
                    ]
                  : []
              }
              // disabledDate={(current) => {
              //   return current && current >= dayjs();
              // }}
              onChange={(e, _) => {
                const obj = {
                  start_date: e?.[0]
                    ? dayjs(e?.[0]).format("YYYY-MM-DD")
                    : null,
                  end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                };
                getList({
                  ...obj,
                  selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                });
                AdsPerformanceSnapshot({
                  ...obj,
                  selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                });

                setFilters(obj);
              }}
            />
          </div>
        </div>
      </div>
      <Spin spinning={snapshotLoading}>
        <div>
          <div className="row g-3 mb-4 mt-3">
            {SellerStatus.slice(0, 6)?.map((d, i) => (
              <div key={i} className="col">
                <div
                  style={{
                    background: "#FFF",
                    minWidth: "150px",

                    borderRight: i === 6 ? "" : "1px solid #80808036",
                  }}
                  className="card px-0"
                >
                  <div className="card-body px-4 py-4 d-flex align-items-center justify-content-between">
                    <div>
                      <div className=" text-gray-600 fs-5 mt-2 mb-2">
                        {d?.title}
                      </div>
                      <div className="text-gray-900 fw-semibold fs-2 ">
                        {d?.prefix}
                        {formatNumberWithCommas(
                          parseFloat(
                            parseFloat(
                              snapshotData?.snapshotData?.[d?.key] || 0
                            ).toFixed(0)
                          )
                        )}
                        {d?.suffix}
                      </div>
                    </div>
                    <div className="d-grid" style={{ justifyItems: "end" }}>
                      {d?.subTitle && (
                        <>
                          <div className="fs-5 text-primary mt-2 mb-2">
                            {d?.subTitle}
                          </div>
                          <div className="text-gray-700 text-primary fw-semibold fs-4 ">
                            {d?.subPrefix}
                            {formatNumberWithCommas(
                              parseFloat(
                                parseFloat(
                                  snapshotData?.snapshotData?.[d?.subKey] || 0
                                ).toFixed(0)
                              )
                            )}
                            {d?.subSuffix}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row g-3">
            {SellerStatus.slice(-6)?.map((d, i) => (
              <div key={i} className="col">
                <div
                  style={{
                    background: "#FFF",
                    minWidth: "150px",

                    borderRight: i === 6 ? "" : "1px solid #80808036",
                  }}
                  className="card px-0"
                >
                  <div className="card-body px-4  py-4 d-flex align-items-center justify-content-between">
                    <div>
                      <div className=" text-gray-600 fs-5 mt-2 mb-2">
                        {d?.title}
                      </div>
                      <div className="text-gray-900 fw-semibold fs-2 ">
                        {d?.prefix}
                        {formatNumberWithCommas(
                          parseFloat(
                            parseFloat(
                              snapshotData?.snapshotData?.[d?.key] || 0
                            ).toFixed(0)
                          )
                        )}
                        {d?.suffix}
                      </div>
                    </div>
                    <div className="d-grid" style={{ justifyItems: "end" }}>
                      {d?.subTitle && (
                        <>
                          <div className="fs-5 text-primary mt-2 mb-2">
                            {d?.subTitle}
                          </div>
                          <div className="text-gray-700 text-primary fw-semibold fs-4 ">
                            {d?.subPrefix}
                            {formatNumberWithCommas(
                              parseFloat(
                                parseFloat(
                                  snapshotData?.snapshotData?.[d?.subKey] || 0
                                ).toFixed(0)
                              )
                            )}
                            {d?.subSuffix}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Spin>
      <div className="card pt-4 mt-5">
        {/* <div className="card-header">
          <div className="card-title"></div>
          <div className="d-none card-toolbar">
            <Tag color="blue-inverse" className="me-2">
              {selectedRange === "custom" ? "Selected period" : selectedRange}
            </Tag>
            <DatePicker.RangePicker
              presets={presetRanges_}
              size="middle"
              style={{
                width: "280px",
                border: "1px solid #D7D7D7",
              }}
              format={"YYYY-MMM-DD"}
              value={
                filters?.start_date && filters?.end_date
                  ? [
                      dayjs(filters?.start_date, "YYYY-MM-DD"),
                      dayjs(filters?.end_date, "YYYY-MM-DD"),
                    ]
                  : []
              }
              disabledDate={(current) => {
                return current && current >= dayjs().subtract(2, "day");
              }}
              onChange={(e, _) => {
                const obj = {
                  start_date: e?.[0]
                    ? dayjs(e?.[0]).format("YYYY-MM-DD")
                    : null,
                  end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                };
                getList({
                  ...obj,
                  selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                });
                setFilters(obj);
              }}
            />
          </div>
        </div> */}
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
              <thead>
                <tr>
                  <th />
                  {Object?.entries(
                    columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                  )
                    ?.filter((d) => {
                      return selectedTab === "5" && d?.[0] === "%"
                        ? d?.[0] !== "%"
                        : d;
                    })
                    ?.map(([key, value], i) => {
                      return (
                        <th
                          key={i}
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className={`${key} text-end  pe-3 py-3`}
                        >
                          {key}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                <tr>
                  <td
                    style={{ background: "transparent" }}
                    className="d-flex align-items-center fixed-column justify-content-start"
                  >
                    <h4
                      style={{
                        marginBottom: "0px",
                        color: "#515151",
                        fontWeight: 500,
                      }}
                    >
                      Paid Sales
                    </h4>
                  </td>
                  <td colSpan={9}></td>
                </tr>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>

                {convertedData?.salesData?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                    >
                      <div className="d-flex align-items-center">
                        <span style={{ textTransform: "capitalize" }}>
                          {d?.type?.split("_")?.join(" ")}
                        </span>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      return (
                        <td
                          key={i}
                          className="text-end pe-3"
                          style={{
                            borderBottom: `${
                              value?.includes("pr_share") ||
                              value?.includes("month_pre") ||
                              value === "expected_variance_pr"
                                ? "1px solid #00000000"
                                : "1px solid #E5E5E5"
                            }`,
                          }}
                        >
                          {value === "variance_pect" ||
                          value === "tmly_variance_pect"
                            ? getValueShow(d?.[value])
                            : (value?.includes("ratio") ||
                              value?.includes("pect")
                                ? ""
                                : "$") + d?.[value]
                            ? (value?.includes("ratio") ||
                              value?.includes("pect")
                                ? ""
                                : "$") +
                              formatNumberWithCommas(d?.[value]) +
                              (value?.includes("ratio") ||
                              value?.includes("pect")
                                ? "%"
                                : "")
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                ))}

                <tr>
                  <td
                    className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                    style={{
                      borderBottom: "1px solid #E5E5E5",
                      background: "#e4f3ff",
                      color: "#015986",
                      fontWeight: 500,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <span>Total Paid Sales</span>
                    </div>
                  </td>
                  {Object?.entries(
                    columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                  )?.map(([key, value], i) => {
                    if (ratioView(key)) {
                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        ></td>
                      );
                    }
                    return (
                      <td
                        key={i}
                        className="text-end   pe-3"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontWeight: 500,
                        }}
                      >
                        {convertedData?.salasTotal?.[value] == 0 ||
                        !convertedData?.salasTotal?.[value]
                          ? "-"
                          : value === "variance_pect" ||
                            value === "tmly_variance_pect"
                          ? getValueShow(
                              convertedData?.salasTotal?.[value] || "0"
                            )
                          : `$${signConvert(
                              `${convertedData?.salasTotal?.[value]}`
                            )}`}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive mt-5">
            <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
              <thead>
                <tr>
                  <th />
                  {Object?.entries(
                    columnsFilterAdsCost?.[selectedRange?.split(" ")?.join("_")]
                  )
                    ?.filter((d) => {
                      return selectedTab === "5" && d?.[0] === "%"
                        ? d?.[0] !== "%"
                        : d;
                    })
                    ?.map(([key, value], i) => {
                      return (
                        <th
                          key={i}
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className={`${key} text-end  pe-3 py-3`}
                        >
                          {key}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                <tr>
                  <td
                    style={{ background: "transparent" }}
                    className="d-flex align-items-center fixed-column justify-content-start"
                  >
                    <h4
                      style={{
                        marginBottom: "0px",
                        color: "#515151",
                        fontWeight: 500,
                      }}
                    >
                      Advertising Cost
                    </h4>
                  </td>
                  <td colSpan={9}></td>
                </tr>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                {convertedData?.costData?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                    >
                      <div className="d-flex align-items-center">
                        <span style={{ textTransform: "capitalize" }}>
                          {d?.type?.split("_")?.join(" ")}
                        </span>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilterAdsCost?.[
                        selectedRange?.split(" ")?.join("_")
                      ]
                    )?.map(([key, value], i) => {
                      return (
                        <td
                          key={i}
                          className="text-end pe-3"
                          style={{
                            borderBottom: `${
                              value?.includes("pr_share") ||
                              value?.includes("month_pre") ||
                              value === "expected_variance_pr"
                                ? "1px solid #00000000"
                                : "1px solid #E5E5E5"
                            }`,
                          }}
                        >
                          {value === "variance_pect" ||
                          value === "tmly_variance_pect"
                            ? getValueShow(d?.[value])
                            : (value?.includes("ratio") ||
                              value?.includes("acos") ||
                              value?.includes("pect")
                                ? ""
                                : "$") + d?.[value]
                            ? (value?.includes("ratio") ||
                              value?.includes("acos") ||
                              value?.includes("pect")
                                ? ""
                                : "$") +
                              formatNumberWithCommas(d?.[value]) +
                              (value?.includes("ratio") ||
                              value?.includes("acos") ||
                              value?.includes("pect")
                                ? "%"
                                : "")
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                ))}

                <tr>
                  <td
                    className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                    style={{
                      borderBottom: "1px solid #E5E5E5",
                      background: "#e4f3ff",
                      color: "#015986",
                      fontWeight: 500,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <span>Total Advertising Costs</span>
                    </div>
                  </td>
                  {Object?.entries(
                    columnsFilterAdsCost?.[selectedRange?.split(" ")?.join("_")]
                  )?.map(([key, value], i) => {
                    if (ratioView(key)) {
                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        ></td>
                      );
                    }

                    return (
                      <td
                        key={i}
                        className="text-end   pe-3"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontWeight: 500,
                        }}
                      >
                        {convertedData?.costTotal?.[value] == 0 ||
                        !convertedData?.costTotal?.[value]
                          ? "-"
                          : value === "variance_pect" ||
                            value === "tmly_variance_pect"
                          ? getValueShow(
                              convertedData?.costTotal?.[value] || "0"
                            )
                          : `${
                              value === "this_month_acos" ? "" : "$"
                            }${signConvert(
                              `${convertedData?.costTotal?.[value]}`
                            )}${value === "this_month_acos" ? "%" : ""}`}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card mt-5 p-5">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      {PremiumContent({
        contextValue: contextValue,
        PCAUpdate: (e, _) => PCAUpdate(e, _),
      })}
      {contextValue?.data?.user_?.pca_connected === 1 ? (
        <div className="performance_part">
          <div id="kt_app_toolbar" className="app-toolbar mb-5 mt-5 ">
            <div
              id="kt_app_toolbar_container"
              className="app-container d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                  Advertising Performance
                </h1>
              </div>
              <div>
                <Tabs type="line" defaultActiveKey="1" items={items} />
              </div>
            </div>
          </div>

          <div className="d-flex gap-3">
            <Select className="w-200px" size="middle" placeholder="Placement" />
            <Select
              className="w-200px"
              size="middle"
              placeholder="Targetting"
            />
            <Select className="w-200px" size="middle" placeholder="Medium" />
            <Select
              className="w-200px"
              size="middle"
              placeholder="Landing Page"
            />
          </div>

          <div className="d-flex  mt-5 gap-4" style={{ overflow: "auto" }}>
            {[14, 13, 12, 11]?.map((d, i) => (
              <>
                <div className="card " style={{ minWidth: "390px" }}>
                  <div className="card-header  mt-5" style={{ minHeight: 0 }}>
                    <div className="card-title">Week {d}</div>
                  </div>
                  <div className="card-body py-3 px-0">
                    <div className="box-content px-5 mx-5">
                      <div className="row">
                        <div className="col-12 d-grid">
                          <label>Paid Sales</label>
                          <b>$32</b>
                        </div>
                        <div className="col-12 d-grid mt-3">
                          <label>Unit Sold</label>
                          <b>30</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-grid text-end">
                          <label>ACOS</label>
                          <b>
                            <Tag color="red" bordered={false}>
                              {-21}%
                            </Tag>
                          </b>
                        </div>
                        <div className="col-12 d-grid text-end mt-3">
                          <label>ROAS</label>
                          <b>0.00</b>
                        </div>
                      </div>
                    </div>
                    <div
                      className="box-content px-5 mx-5"
                      style={{ background: "#edf1f5" }}
                    >
                      <div className="row">
                        <div className="col-12 d-grid">
                          <label>Advertising Cost</label>
                          <b>$0</b>
                        </div>
                        <div className="col-12 d-grid mt-3">
                          <label>Viewable Impressions</label>
                          <b>0</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-grid text-end">
                          <label>TACOS</label>
                          <b>
                            <Tag color="green" bordered={false}>
                              54%
                            </Tag>
                          </b>
                        </div>
                        <div className="col-12 d-grid mt-3 text-end">
                          <label>Clicks</label>
                          <b>0.00</b>
                        </div>
                      </div>
                    </div>

                    <div className="box-content px-5 mx-5">
                      <div className="row">
                        <div className="col-12 d-grid">
                          <label>Avg CTR</label>
                          <b>0%</b>
                        </div>
                        <div className="col-12 d-grid mt-3">
                          <label>Avg CPC</label>
                          <b>0.00</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-grid text-end">
                          <label>Avg CPA</label>
                          <b>23.00</b>
                        </div>
                        <div className="col-12 d-grid mt-3 text-end">
                          <label>Avg Impression Share</label>
                          <b>0%</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <Watermark content="Sample">
          <img src={`/assets/media/ads360.png`} style={{ width: "100%" }} />
        </Watermark>
      )}
      {ReportData(visible, setVisible, reportingData)}
    </WrapperContant>
  );
};

export default AdvertisingDashboard;
