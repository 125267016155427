import { Badge, Empty, Menu, Spin, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import payload from "./lib";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../apis";
import { Icon } from "@iconify/react";
import { GetLinks, LOGINURL } from "../../config";
import { GlobalContext } from "../../common-context";

const Sidebar = (props) => {
  const {
    setList,
    list,
    setLoading,
    setUsersView,
    loading,
    menuHide,
    type,
    user,
    allList,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const contextValue = useContext(GlobalContext);
  // contextValue
  const selectedMenu = () => {
    return location?.pathname || "/";
  };

  const transformedMenu = (menus) => {
    return menus?.map((menu, i) => {
      const transformedMenu = {
        index: menu.title, // Adding the index property
        key: `/${menu.url || i + 1}`,
        icon: menu.icon ? (
          <Icon
            icon={menu.icon || "mdi:add"}
            width="22"
            height="22"
            style={{ cursor: "pointer", color: "#FFF" }}
          />
        ) : (
          ""
        ),
        label: (
          <div className="d-flex align-items-center">
            {!menu.url ? (
              menu.title
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/${menu.url}`);
                }}
              >
                {menu.title}
              </div>
            )}

            {menu.children &&
              menu.children?.filter((d) => d?.status).length > 0 && (
                <Badge
                  className="ms-2"
                  count={menu.children?.filter((d) => d?.status).length}
                  showZero
                  color="#01c0c8"
                />
              )}
          </div>
        ),
      };

      if (menu.children && menu.children.length > 0) {
        transformedMenu.children = menu.children
          ?.sort((a, b) => a.child_sort_order - b.child_sort_order)
          ?.filter((r) => r?.status === 1)
          ?.map((child) => ({
            index: child.title, // Adding the index property
            key: `${menu.url ? "/" + menu.url : ""}/${child.url}`,
            icon: child.icon ? (
              <Icon
                icon={child.icon || "mdi:add"}
                width="22"
                height="22"
                style={{ cursor: "pointer", color: "#FFF" }}
              />
            ) : (
              ""
            ),
            label: GetLinks(
              menu.url ? `/${menu.url}/${child.url}` : `/${child.url}`,
              child.title
            ),
          }));
      }

      return transformedMenu;
    });
  };

  const getMenus = async () => {
    setLoading(true);
    const response = await MakeApiCall(
      `menu-item?isAll=0&per-page=100`,
      "get",
      null,
      true
    );

    if (response?.status) {
      try {
        const listsMenus = response?.data?.records?.filter(
          (d) => d?.status === 1
        );

        const index = [
          ...listsMenus
            ?.map((g) => g?.children)
            ?.map((arr) => arr)
            ?.reduce((acc, val) => acc?.concat(val), []),
          ...listsMenus,
        ]?.findIndex((d) => d?.url === location?.pathname?.split("/")?.[1]);

        setList(listsMenus);
        setLoading(false);
        if (
          index === -1 &&
          !location?.pathname?.includes("spapi-callback") &&
          !location?.pathname?.includes("callbackads")
        ) {
          history.push(`/${listsMenus?.[0]?.url}`);
        }
      } catch (error) {
        setList([]);
        setLoading(false);
      }
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (type === "admin") return;
    getMenus();

    return () => {};
  }, []);

  return (
    <Wrapper
      id="kt_aside"
      // className={`aside   `}
      className={`aside   ${menuHide ? "hideMenu" : ""}`}
      data-kt-aside-minimize="on"
    >
      {/*begin::Aside Toolbarl*/}
      <div
        className="aside-toolbar flex-column-auto border-gray-700 pb-2 border-bottom  "
        id="kt_aside_toolbar"
      >
        {/*begin::Aside user*/}
        {/*begin::User*/}
        <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
          {/*begin::Symbol*/}
          <div className="symbol symbol-60px symbol-circle">
            <img
              src={user?.photo || "https://via.placeholder.com/50"}
              onError={(e) => (e.target.src = "https://via.placeholder.com/50")}
              className="border border-primary p-1"
              alt
              style={{
                borderRadius: "100%",
                width: "60px",
                height: "60px",
                objectFit: "contain",
              }}
            />
          </div>
          {/*end::Symbol*/}
          {/*begin::Wrapper*/}
          {!menuHide && (
            <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
              {/*begin::Section*/}
              <div className="d-flex">
                {/*begin::Info*/}
                <div className="flex-grow-1 me-2">
                  {/*begin::Username*/}
                  <a
                    href="#"
                    className="text-white text-hover-primary fs-6 fw-bold"
                  >
                    {user?.name || "-"}
                  </a>
                  {/*end::Username*/}
                  {/*begin::Description*/}
                  <span className="text-gray-400 fw-semibold d-block fs-8 mb-1">
                    {user?.email || "-"}
                  </span>
                  {/*end::Description*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Section*/}
            </div>
          )}
          {/*end::Wrapper*/}
        </div>
      </div>
      {/*end::Aside Toolbarl*/}
      {/*begin::Aside menu*/}
      <div className="aside-menu flex-column-fluid">
        {/*begin::Aside Menu*/}
        <div
          className="hover-scroll-overlay-y mx-3 my-5 my-lg-5"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="5px"
          style={{ height: "calc(100vh - 201px)", overflow: "auto" }}
        >
          {/*begin::Menu*/}
          {loading && type !== "admin" ? (
            <div
              style={{
                position: "absolute",
                inset: 0,
                margin: "auto",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <Spin style={{ color: "red" }} />
            </div>
          ) : type !== "admin" && list?.length === 0 ? (
            <div
              style={{
                position: "absolute",
                inset: 0,
                margin: "auto",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
                description={
                  <Typography.Text>
                    <span style={{ color: "#FFF" }}>No Menus</span>
                  </Typography.Text>
                }
              />
            </div>
          ) : (
            <Menu
              theme="dark"
              inlineCollapsed={menuHide}
              mode="inline"
              selectedKeys={
                selectedMenu() === "/" ? "/dashboard" : selectedMenu()
              }
              items={type !== "admin" ? transformedMenu(list) : payload?.[type]}
            />
          )}

          {/* */}
          {/*end::Menu*/}
        </div>
        {/*end::Aside Menu*/}
      </div>
      {/*end::Aside menu*/}
      {/*begin::Footer*/}
    </Wrapper>
  );
};

export default Sidebar;
