import { Button, Form, Input, Modal, Select, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { configModal } from "../../../../config";

const AddData = ({
  visible,
  onClose,
  modalType,
  callAPI,
  selectedRow,
  seller_id,
}) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [parameterGroupList, setParameterGroupList] = useState([]);
  const [parameterGroupLoading, setParameterGroupLoading] = useState(true);
  const [sellerParametersList, setSellerParametersList] = useState([]);
  const [sellerParametersLoading, setSellerParametersLoading] = useState(false);

  const UpdateAction = async (data) => {
    const response = await MakeApiCall(
      `seller-parameter-mapping/update/${selectedRow?.id__}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      modal
        .success(configModal("Seller Parameter", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      modal.error(configModal("Seller Parameter", response?.message));

      message.destroy();
    }
  };

  const AddUpdate = async (data) => {
    const response = await MakeApiCall(
      `seller-parameter-mapping/store`,
      "post",
      { ...data, seller_id, seller_parameter_id: data?.seller_id },
      true
    );

    if (response?.status) {
      message.destroy();
      modal
        .success(configModal("Seller Parameter", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      message.destroy();
      modal.error(configModal("Seller Parameter", response?.message));
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        message.destroy();
        message.loading("Loading...", 0);

        console.log(values, modalType, "values");
        if (modalType === "Add") {
          AddUpdate(values);
        } else {
          UpdateAction({
            param_value: values?.param_value,
            param_upper_value: values?.param_upper_value || 0,
            param_lower_value: values?.param_lower_value || 0,
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    onClose();
  };
  const SellerParametersMappingList = async (id) => {
    setSellerParametersLoading(true);
    const response = await MakeApiCall(
      `seller-parameter-mapping/parameters/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length === 1) {
        form.setFieldsValue({
          seller_id: response?.data?.[0]?.id || "",
        });
      }

      setSellerParametersList(response?.data || []);
      setSellerParametersLoading(false);
    } else {
      setSellerParametersList([]);
      message.destroy();
      setSellerParametersLoading(false);
      message.warning(response?.message);
    }
  };
  const GetParameterGroupList = async () => {
    setParameterGroupLoading(true);
    const response = await MakeApiCall(
      `seller-parameter-mapping/parameter-group/${seller_id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length === 1) {
        form.setFieldsValue({
          seller_parameter_id: response?.data?.[0]?.id || "",
        });
        SellerParametersMappingList(response?.data?.[0]?.id);
      }
      setParameterGroupList(response?.data || []);
      setParameterGroupLoading(false);
    } else {
      setParameterGroupList([]);
      message.destroy();
      setParameterGroupLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    GetParameterGroupList();

    return () => {};
  }, []);

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      const {
        param_lower_value,
        param_upper_value,
        param_value,

        seller_parameter,
      } = selectedRow;
      SellerParametersMappingList(seller_parameter?.parameter_group_id);

      form.setFieldsValue({
        seller_parameter_id: seller_parameter?.parameter_group_id,
        seller_id: seller_parameter?.id,
        param_value: param_value,
        param_lower_value: param_lower_value,
        param_upper_value: param_upper_value,
      });
    }
  }, [selectedRow]);

  return (
    <Modal
      title={`${modalType === "Add" ? "Add New" : "Edit"} Seller Parameter`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="seller-parameter">
        <>
          <Form.Item
            label="Parameter Group"
            name="seller_parameter_id"
            rules={[
              {
                required: true,
                message: "Please input your Parameter Group!",
              },
            ]}
          >
            <Select
              options={parameterGroupList?.map((d) => ({
                label: d?.group_name,
                value: d?.id,
              }))}
              onChange={(e) => {
                form.setFieldsValue({
                  seller_id: "",
                  param_value: null,
                  param_lower_value: null,
                  param_upper_value: null,
                });
                SellerParametersMappingList(e);
              }}
              loading={parameterGroupLoading}
            />
          </Form.Item>

          <Form.Item
            label="Parameter Name"
            name="seller_id"
            rules={[
              {
                required: true,
                message: "Please input your Parameter Name!",
              },
            ]}
          >
            <Select
              disabled={sellerParametersList?.length === 1}
              loading={sellerParametersLoading}
              options={sellerParametersList?.map((d) => ({
                label: d?.param_name,
                value: d?.id,
              }))}
            />
          </Form.Item>
        </>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const seller_Id = getFieldValue("seller_id");

            const paramType = sellerParametersList?.find(
              (d) => d?.id === seller_Id
            )?.param_type;
            if (sellerParametersList?.length === 0 || !seller_Id) {
              return <></>;
            }
            return (
              <>
                <Form.Item label={<>Default Type</>}>
                  <Input value={paramType} disabled />
                </Form.Item>
                {["Text", "Single%", "Single$", "SingleInt"].includes(
                  paramType
                ) && (
                  <Form.Item
                    label={<>Default Value </>}
                    name="param_value"
                    rules={[
                      {
                        required: true,
                        message:
                          "The Parameter you have added requires a Default Value. Please enter and submit again.",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || paramType === "Text") {
                            return Promise.resolve();
                          }
                          const regex = /^(?:\d+|\d+\.\d+)$/;
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error("Invalid Value, please input a number!")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {/* Conditionally render Default Lower Value */}
                {!["Text", "Single%", "Single$", "SingleInt"].includes(
                  paramType
                ) && (
                  <Form.Item
                    label="Default Lower Value"
                    name="param_lower_value"
                    rules={[
                      {
                        required: true,
                        message:
                          "The Parameter you have added requires a Default Lower Value. Please enter and submit again.",
                      },
                      {
                        pattern: /^(?:\d+|\d+\.\d+)$/,
                        message: "Please enter a valid number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                {/* Conditionally render Default Upper Value */}
                {!["Text", "Single%", "Single$", "SingleInt"].includes(
                  paramType
                ) && (
                  <Form.Item
                    label="Default Upper Value"
                    name="param_upper_value"
                    rules={[
                      {
                        required: true,
                        message:
                          "The Parameter you have added requires a Default Upper Value. Please enter and submit again.",
                      },
                      {
                        pattern: /^(?:\d+|\d+\.\d+)$/,
                        message: "Please enter a valid number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default AddData;
