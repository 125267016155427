import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
} from "antd";
import { MakeApiCall } from "../../../../../apis";
import Icons from "../../../../../components/icons";

const AddCaralog = ({
  // visible,
  setVisible,
  // setList,
  pageType,
  id,
  // selectedRow,
  menuList,
  menuLoading,
  locationList,
  locationLoading,
  getList,
}) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [filteredLocations, setFilteredLocations] = useState(locationList);
  const handleMenuItemSelect = (value) => {
    setSelectedMenuItem(value);

    // Filter locations based on the selected menu item
    // This assumes that each location in locationList has an attribute that links it to a menu item
    const updatedLocations = locationList.filter(
      (location) => parseInt(location.menu_item_id) === parseInt(value)
    );
    setFilteredLocations(updatedLocations);
  };

  const addCatelogLocation = async (data) => {
    const response = await MakeApiCall(
      `content-location-catalog${
        Object.keys(selectedRow)?.length === 0 ? "" : `/${selectedRow?.id}`
      }`,
      Object.keys(selectedRow)?.length === 0 ? "post" : "put",
      data,
      true
    );

    if (response?.status) {
      getList();
      setLoading(false);
      form.resetFields();
      setVisible(false); // Close modal after form submission
    } else {
      setLoading(false);
      message.warning(
        Object.values(response?.data || {})?.[0] || response?.message
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        addCatelogLocation(values);
      })
      .catch((info) => {});
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const getRecord = async () => {
    const response = await MakeApiCall(
      `content-location-catalog/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedRow(response?.data || {});
      setPageLoading(false);
      // setLoading(false);
    } else {
      setSelectedRow({});
      // setLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    // setLoading(true);
    return () => {};
  }, []);

  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length === 0) return;
    const { menu_item_id, location_id, title } = selectedRow;

    form.setFieldsValue({
      menu_item_id: menu_item_id?.toString(),
      location_id: location_id,
      title: title,
    });
    handleMenuItemSelect(menu_item_id?.toString());
  }, [selectedRow, locationList, menuList]);

  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <span
              className="me-3 cursor-pointer"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icons type="home" />
            </span>
            /{" "}
            {Object.keys(selectedRow)?.length === 0
              ? "Content Location Catalog"
              : "Edit Content Location Catalog"}
          </div>
        </div>
        <div className="px-10 pt-10">
          <Form form={form} layout="vertical">
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please input the title!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="menu_item_id"
              label="Menu Item"
              rules={[
                { required: true, message: "Please input the Menu Item!" },
              ]}
            >
              <Select
                options={menuList}
                loading={menuLoading}
                style={{ width: "100%" }}
                onChange={(e) => {
                  form.resetFields(["location_id"]);
                  handleMenuItemSelect(e);
                }}
                placeholder="Select Menu"
              />
            </Form.Item>

            <Form.Item
              name="location_id"
              label="Location"
              rules={[
                { required: true, message: "Please input the Location!" },
              ]}
            >
              {/* <Input placeholder={"Enter Location"} /> */}
              <Select
                // options={locationList?.map((d) => ({
                //   label: d?.title,
                //   value: d?.id,
                // }))}
                loading={locationLoading}
                style={{ width: "100%" }}
                placeholder={"Select Location"}
              >
                {filteredLocations.map((location) => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-end">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            className="ms-3"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddCaralog;
