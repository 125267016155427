import { Table } from "antd";
import React from "react";
import { PropsFilter, RenderTable } from "../../../../config";

export default function FtpCredentials(props) {
  console.log(props?.pageTitle, "props");
  const dataSource = [
    {
      key: "1",
      hostName: "ftp.example.com",
      directory: "/public_html",
      username: "user1",
      password: "password123",
    },
    {
      key: "2",
      hostName: "ftp.example.net",
      directory: "/home/user",
      username: "user2",
      password: "securePass",
    },
    // Add more entries as needed
  ];
  const columns = [
    {
      title: "FTP Host Name",
      dataIndex: "hostName",
      key: "hostName",
    },
    {
      title: "Directory",
      dataIndex: "directory",
      key: "directory",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
  ];
  return (
    <div
      id="kt_content_container"
      className="container-fluid position-relative"
    >
      <div className="row mt-5">
        {/* Column */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">{props?.pageTitle}</div>
            </div>
            <div className="card-body pt-0">
              <Table
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                dataSource={dataSource}
                columns={columns?.map((d) => ({
                  ...d,
                  className: "minWidth-table",
                  ...PropsFilter(d?.key),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
